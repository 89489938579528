<template>
  <div class="columns mb-2">
    <div class="column mt-2">
      <a class="cut-text max-width-800 tag is-info is-light is-medium is-justify-content-left" target="_blank" :href="`http://qsssgl.com/?url=${siteInfo.url}`">{{ siteInfo.title }}</a>
      <a class="button is-light is-link is-outlined is-small is-rounded ml-2" target="_blank" :href="`http://qsssgl.com/?url=${siteInfo.url}wp-login.php`">{{ l("Admin") }}</a>
    </div>
    <div class="column">
      <div class="field is-grouped is-grouped-right">
        <div class="buttons mb-0 is-right has-addons">
          <a class="button mb-0 is-light is-link" :class="{ 'is-loading': isLoadingAll }" @click.prevent="crawlInterface('language')">
            <span>{{ l("R. Site Language") }}</span>
          </a>
          <a class="button mb-0 is-light is-link" :class="{ 'is-loading': isLoadingAll }" @click.prevent="crawlInterface('wpapi')">
            <span>{{ l("R. WP-API") }}</span>
          </a>
          <a class="button mb-0 is-link" :class="{ 'is-loading': isLoadingAll }" @click.prevent="crawlInterface('site')">
            <span>{{ l("Rescan Site") }}</span>
          </a>
        </div>
        <div class="buttons is-right">
          <a class="button is-danger" @click.prevent="displayDeleteModal = selectedItemsMap.size ? true : false">
            <span>{{ l("Remove") }}</span>
          </a>
        </div>
        <PostListTopDelete v-if="displayDeleteModal" :displayDeleteModal.sync="displayDeleteModal" :selectedItemsMap="selectedItemsMap" @crawlWpApi="crawlWpApi" :siteInfo="siteInfo" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { waitFor } from "../sharedfn.js";
import PostListTopDelete from "./PostList_Top_Delete.vue";

export default {
  name: "PostListTop",
  props: {
    selectedItemsMap: Map,
    currLine: Number,
    siteInfo: Object,
  },
  components: {
    PostListTopDelete,
  },
  data() {
    return {
      displayDeleteModal: false,
      isLoadingAll: false,
    };
  },

  methods: {
    async crawlInterface(type) {
      this.isLoadingAll = true;
      try {
        await axios.get(`${this.apiInterface.qcrawl}?url=${encodeURIComponent(this.siteInfo?.url)}&type=${type}`);
        while (Object.keys((await axios.get(`${this.apiInterface.qget}?url=${encodeURIComponent(this.siteInfo?.url)}&type=${type}`)).data).length !== 0) {
          await waitFor(3000);
          type === "site" && (await waitFor(10000));
        }
        this.$emit("refreshList");
      } catch (err) {
        console.log(err.message);
      }
      this.isLoadingAll = false;
    },
  },
};
</script>

<style></style>
