<template>
  <div>
    <div class="columns mb-0">
      <div class="column is-4 pb-0">
        <div class="field mb-0">
          <div class="control">
            <input class="input" type="text" spellcheck="false" size="50" v-model.lazy.trim="searchQr" ref="search" :placeholder="l('Search')" />
          </div>
        </div>
      </div>
      <div class="column is-8 pb-0">
        <div class="field mb-0 is-grouped is-grouped-right is-grouped-multiline">
          <p class="control">
            <a class="button is-warning" @click.prevent="displayQueueModal = true"
              ><span>{{ l("Queue") }}: {{ queue }}</span></a
            >
          </p>
          <p class="control">
            <a class="button is-link" :class="{ 'is-loading': isLoadingRf }" @click.prevent="refreshDataForce"
              ><span>{{ l("Refresh") }}</span></a
            >
          </p>
          <div class="control is-flex is-align-items-center">
            <p class="select">
              <select v-model="extraColCp">
                <option disabled selected value="">{{ l("Vue") }}</option>
                <option value="homePageStat">{{ l("homePageStat") }}</option>
                <option value="internalLink">{{ l("internalLink") }}</option>
                <option value="errorLink">{{ l("errorLink") }}</option>
                <option value="postKeywords">{{ l("postKeywords") }}</option>
              </select>
            </p>
          </div>
          <div class="control is-flex is-align-items-center">
            <p class="select">
              <select v-model="amountCp">
                <option disabled selected value="">{{ l("Nb") }}</option>
                <option>12</option>
                <option>30</option>
                <option>50</option>
                <option>100</option>
                <option>150</option>
                <option>200</option>
                <option>500</option>
                <option>1000</option>
              </select>
            </p>
          </div>
          <p class="control is-flex is-align-items-center">
            <label class="checkbox">
              <input @change="onInputUrlChecked" type="checkbox" />
              {{ l("Origin") }}
            </label>
          </p>
          <p class="control">
            <a class="button is-danger" @click.prevent="displayDeleteModal = selectedItemsMap.size ? true : false">
              <span>{{ l("Remove") }}</span>
            </a>
          </p>
          <p class="control">
            <a class="button is-info" @click.prevent="importNewSites">
              <span>{{ l("Import") }}</span>
            </a>
          </p>
          <p class="control">
            <a class="button is-success" @click.prevent="displayAddModal = true">
              <span>{{ l("Add") }}</span>
            </a>
          </p>
        </div>
        <SiteListTopQueue v-if="displayQueueModal" :displayQueueModal.sync="displayQueueModal" :queue.sync="queue" />
        <SiteListTopAdd v-if="displayAddModal" :displayAddModal.sync="displayAddModal" :queue.sync="queue" />
        <SiteListTopDelete v-if="displayDeleteModal" :displayDeleteModal.sync="displayDeleteModal" :selectedItemsMap="selectedItemsMap" />
        <SiteListTopImport v-if="displayImportModal" :displayImportModal.sync="displayImportModal" :newSites="newSites" @clearNewSites="onClearNewSites" :queue.sync="queue" />
      </div>
    </div>
    <div class="columns mb-0">
      <div class="column is-12">
        <div class="field mb-0 is-grouped is-grouped-right">
          <div class="buttons is-right has-addons">
            <a class="button is-light is-link" @click.prevent="crawlInterface('homepage')">
              <span>{{ l("R. Home Page") }}</span>
            </a>
            <a class="button is-light is-link" @click.prevent="crawlInterface('admin')">
              <span>{{ l("R. Admin Page") }}</span>
            </a>
            <a class="button is-light is-link" @click.prevent="crawlInterface('info')">
              <span>{{ l("R. Site State") }}</span>
            </a>
            <a class="button is-light is-link" @click.prevent="crawlInterface('language')">
              <span>{{ l("R. Site Language") }}</span>
            </a>
            <a class="button is-light is-link" @click.prevent="crawlInterface('wpapi')">
              <span>{{ l("R. WP-API") }}</span>
            </a>
            <a class="button is-link" @click.prevent="crawlInterface('site')">
              <span>{{ l("Rescan Site") }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import SiteListTopQueue from "./SiteList_Top_Queue.vue";
import SiteListTopAdd from "./SiteList_Top_Add.vue";
import SiteListTopDelete from "./SiteList_Top_Delete.vue";
import SiteListTopImport from "./SiteList_Top_Import.vue";

export default {
  name: "SiteListTop",
  props: {
    showInputUrl: Boolean,
    amount: Number,
    searchQuery: String,
    queueNb: Number,
    extraCol: String,
    selectedItemsMap: Map,
    currLine: String,
  },
  components: {
    SiteListTopQueue,
    SiteListTopAdd,
    SiteListTopDelete,
    SiteListTopImport,
  },
  data() {
    return {
      displayAddModal: false,
      displayQueueModal: false,
      displayDeleteModal: false,
      displayImportModal: false,
      isLoadingRf: false,
      newSites: [],
    };
  },

  computed: {
    searchQr: {
      set(newVal) {
        this.$emit("update:searchQuery", newVal);
      },
      get() {
        return this.searchQuery;
      },
    },
    queue: {
      set(newVal) {
        this.$emit("update:queueNb", newVal);
      },
      get() {
        return this.queueNb;
      },
    },
    amountCp: {
      set(newVal) {
        this.$emit("update:amount", +newVal);
      },
      get() {
        return this.amount;
      },
    },
    extraColCp: {
      set(newVal) {
        this.$emit("update:extraCol", newVal);
      },
      get() {
        return this.extraCol;
      },
    },
  },

  mounted() {
    //this.$refs.search.focus();
    this.getQueue();
  },

  methods: {
    async refreshDataForce() {
      this.isLoadingRf = true;
      try {
        await axios.get(`${this.apiInterface.refresh}`);
        await this.getQueue();
        this.$emit("refreshList");
      } catch (err) {
        console.log(err.message);
      }
      this.isLoadingRf = false;
    },

    async getQueue() {
      try {
        const response = await axios.get(`${this.apiInterface.qall}`);
        const resultsArray = response.data.results ?? [];
        this.queue = resultsArray.length;
      } catch (err) {
        console.log(err.message);
      }
    },

    async crawlInterface(type = "page") {
      try {
        for (const [, url] of this.selectedItemsMap) {
          await axios.get(`${this.apiInterface.qcrawl}?url=${encodeURIComponent(url)}&type=${type}`);
        }
      } catch (err) {
        console.log(err.message);
      }
      this.$emit("clearSelectedItems");
      await this.getQueue();
    },

    onInputUrlChecked() {
      this.$emit("update:showInputUrl", !this.showInputUrl);
    },

    async importNewSites() {
      try {
        const response = await axios.get(`${this.apiInterface.importnewsites}`);
        this.newSites = response.data.newSites ?? [];
        this.displayImportModal = true;
      } catch (err) {
        console.log(err.message);
      }
    },

    onClearNewSites() {
      this.newSites = [];
    },
  },
};
</script>

<style></style>
