<template>
  <tr v-switch="extraCol" @click.capture="updateCurrLine(siteCp.fileName)" :class="{ 'has-background-success-light': siteCp.fileName === currLine }">
    <td class="has-text-centered nowrap">
      <div class="control">
        <input class="mt-2" @change="selectItem(siteCp.fileName, siteCp.firstUrl)" v-model="checked" type="checkbox" />
      </div>
    </td>
    <td :class="{ 'has-background-link-light': sort === 'urlUnicodeAccentLess' }">
      <div class="is-flex is-align-items-center my-1">
        <a class="cut-text max-width-400" target="_blank" :href="`http://qsssgl.com/?url=${siteCp.firstUrl}`">{{ siteCp.urlUnicode }}</a>
      </div>
      <div>
        <a v-show="showInputUrl" class="cut-text max-width-400 tag is-info is-rounded" target="_blank" :href="`http://qsssgl.com/?url=${siteCp.inputUrl}`">{{ siteCp.inputUrl }}</a>
      </div>
    </td>
    <td class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'verifySSL' }">
      <span v-if="siteCp.verifySSL == 1"><i class="fa fa-check"></i></span>
      <span v-else-if="siteCp.verifySSL === 'Http'">
        <i class="fa fa-check"></i><span class="subtitle is-7"> {{ siteCp.verifySSL }}</span>
      </span>
      <span v-else-if="siteCp.verifySSL !== null">
        <i class="fa fa-ban"></i><span class="subtitle is-7"> {{ siteCp.verifySSL }}</span>
      </span>
    </td>
    <td class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'language' }">
      <span>{{ siteCp.language }}</span>
    </td>
    <td class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'siteType' }">
      <span>{{ siteCp.siteType }}</span>
    </td>
    <td class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'siteEtat' }">
      <span>{{ siteCp.siteEtat }}</span>
    </td>
    <td v-case="'homePageStat'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'hpTotalLink' }">
      <a @click.prevent="$router.push(`/homepagelink/${siteCp.fileName}`)" :href="`/homepagelink/${siteCp.fileName}`">
        <span>{{ siteCp.hpTotalLink }}</span>
      </a>
    </td>
    <td v-case="'homePageStat'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'hpInterLink' }">
      <a @click.prevent="$router.push(`/homepagelink/${siteCp.fileName}`)" :href="`/homepagelink/${siteCp.fileName}`">
        <span>{{ siteCp.hpInterLink }}</span>
      </a>
    </td>
    <td v-case="'homePageStat'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'hpExterLink' }">
      <a @click.prevent="$router.push(`/homepagelink/${siteCp.fileName}`)" :href="`/homepagelink/${siteCp.fileName}`">
        <span>{{ siteCp.hpExterLink }}</span>
      </a>
    </td>
    <td v-case="'homePageStat'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'hpMenuLinksCombo' }">
      <a @click.prevent="$router.push(`/homepagelink/${siteCp.fileName}`)" :href="`/homepagelink/${siteCp.fileName}`">
        <span>{{ siteCp.hpMenuLinksCombo }}</span>
      </a>
    </td>
    <td v-case="'homePageStat'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'hpMenuLinksError' }">
      <a class="has-text-danger" @click.prevent="$router.push(`/homepagelink/${siteCp.fileName}`)" :href="`/homepagelink/${siteCp.fileName}`">
        <span>{{ siteCp.hpMenuLinksError }}</span>
      </a>
    </td>
    <td v-case="'homePageStat'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'hpTotalError' }">
      <a class="has-text-danger" @click.prevent="$router.push(`/homepagelink/${siteCp.fileName}`)" :href="`/homepagelink/${siteCp.fileName}`">
        <span>{{ siteCp.hpTotalError }}</span>
      </a>
    </td>
    <td v-case="'homePageStat'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'hpLoadingTime' }">
      <a @click.prevent="$router.push(`/homepagelink/${siteCp.fileName}`)" :href="`/homepagelink/${siteCp.fileName}`">
        <span>{{ siteCp.hpLoadingTime }}</span>
      </a>
    </td>
    <td v-case="'homePageStat'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'hpStatus' }">
      <a @click.prevent="$router.push(`/homepagelink/${siteCp.fileName}`)" :href="`/homepagelink/${siteCp.fileName}`">
        <span>{{ siteCp.hpStatus }}</span>
      </a>
    </td>
    <td class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'themeName' }">
      <span>{{ siteCp.themeName }}</span>
    </td>
    <td v-case="'internalLink'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'categoriesNb' }">
      <span>{{ siteCp.categoriesNb }}</span>
    </td>
    <td v-case="'internalLink'" class="nowrap" :class="{ 'has-background-link-light': sort === 'categoriesNames' }">
      <span :class="{ 'cut-text ': cutCatNames }" class="max-width-200" @click.prevent="cutCatNames = !cutCatNames">{{ siteCp.categoriesNames }}</span>
    </td>
    <td v-case="'internalLink'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'postRatio' }">
      <a @click.prevent="$router.push(`/post/-/${siteCp.fileName}`)" :href="`/post/-/${siteCp.fileName}`">
        <span>{{ siteCp.postRatio }}</span>
      </a>
    </td>
    <td v-case="'internalLink'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'postNoCrawl' }">
      <a class="has-text-danger" @click.prevent="$router.push(`/post/noCrawl/${siteCp.fileName}`)" :href="`/post/noCrawl/${siteCp.fileName}`">
        <span>{{ siteCp.postNoCrawl }}</span>
      </a>
    </td>
    <td v-case="'internalLink'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'postLangOth' }">
      <a class="has-text-danger" @click.prevent="$router.push(`/post/othLang/${siteCp.fileName}`)" :href="`/post/othLang/${siteCp.fileName}`">
        <span>{{ siteCp.postLangOth }}</span>
      </a>
    </td>
    <td v-case="'internalLink'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'postNoCategory' }">
      <a class="has-text-danger" @click.prevent="$router.push(`/post/noCategory/${siteCp.fileName}`)" :href="`/post/noCategory/${siteCp.fileName}`">
        <span>{{ siteCp.postNoCategory }}</span>
      </a>
    </td>
    <td v-case="'internalLink'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'postInUncategorized' }">
      <a class="has-text-danger" @click.prevent="$router.push(`/post/inUncategorized/${siteCp.fileName}`)" :href="`/post/inUncategorized/${siteCp.fileName}`">
        <span>{{ siteCp.postInUncategorized }}</span>
      </a>
    </td>
    <td v-case="'internalLink'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'pageRatio' }">
      <a @click.prevent="$router.push(`/page/-/${siteCp.fileName}`)" :href="`/page/-/${siteCp.fileName}`">
        <span>{{ siteCp.pageRatio }}</span>
      </a>
    </td>
    <td v-case="'internalLink'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'pageNoCrawl' }">
      <a class="has-text-danger" @click.prevent="$router.push(`/page/noCrawl/${siteCp.fileName}`)" :href="`/page/noCrawl/${siteCp.fileName}`">
        <span>{{ siteCp.pageNoCrawl }}</span>
      </a>
    </td>
    <td v-case="'internalLink'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'pageLangOth' }">
      <a class="has-text-danger" @click.prevent="$router.push(`/page/othLang/${siteCp.fileName}`)" :href="`/page/othLang/${siteCp.fileName}`">
        <span>{{ siteCp.pageLangOth }}</span>
      </a>
    </td>
    <td v-case="'internalLink'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'subLinkCount' }">
      <a @click.prevent="$router.push(`/sublink/${siteCp.fileName}`)" :href="`/sublink/${siteCp.fileName}`">
        <span>{{ siteCp.subLinkCount }}</span>
      </a>
    </td>
    <td v-case="'internalLink'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'wpApiSubLinkCount' }">
      <a @click.prevent="$router.push(`/sublink/${siteCp.fileName}`)" :href="`/sublink/${siteCp.fileName}`">
        <span>{{ siteCp.wpApiSubLinkCount }}</span>
      </a>
    </td>
    <td v-case="'errorLink'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'errorSum' }">
      <a @click.prevent="$router.push(`/checkedlink/-/${siteCp.fileName}`)" :href="`/checkedlink/-/${siteCp.fileName}`">
        <span>{{ siteCp.errorSum }}</span>
      </a>
    </td>
    <td v-case="'errorLink'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'doubleInternLink' }">
      <a @click.prevent="$router.push(`/checkedlink/doubleInternLink/${siteCp.fileName}`)" :href="`/checkedlink/doubleInternLink/${siteCp.fileName}`">
        <span>{{ siteCp.doubleInternLink }}</span>
      </a>
    </td>
    <td v-case="'errorLink'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'doubleExternLink' }">
      <a @click.prevent="$router.push(`/checkedlink/doubleExternLink/${siteCp.fileName}`)" :href="`/checkedlink/doubleExternLink/${siteCp.fileName}`">
        <span>{{ siteCp.doubleExternLink }}</span>
      </a>
    </td>
    <td v-case="'errorLink'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'intranetDomainError' }">
      <a @click.prevent="$router.push(`/checkedlink/intranetDomainError/${siteCp.fileName}`)" :href="`/checkedlink/intranetDomainError/${siteCp.fileName}`">
        <span>{{ siteCp.intranetDomainError }}</span>
      </a>
    </td>
    <td v-case="'errorLink'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'status400' }">
      <a @click.prevent="$router.push(`/checkedlink/status400/${siteCp.fileName}`)" :href="`/checkedlink/status400/${siteCp.fileName}`">
        <span>{{ siteCp.status400 }}</span>
      </a>
    </td>
    <td v-case="'errorLink'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'status401' }">
      <a @click.prevent="$router.push(`/checkedlink/status401/${siteCp.fileName}`)" :href="`/checkedlink/status401/${siteCp.fileName}`">
        <span>{{ siteCp.status401 }}</span>
      </a>
    </td>
    <td v-case="'errorLink'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'status403' }">
      <a @click.prevent="$router.push(`/checkedlink/status403/${siteCp.fileName}`)" :href="`/checkedlink/status403/${siteCp.fileName}`">
        <span>{{ siteCp.status403 }}</span>
      </a>
    </td>
    <td v-case="'errorLink'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'status404' }">
      <a @click.prevent="$router.push(`/checkedlink/status404/${siteCp.fileName}`)" :href="`/checkedlink/status404/${siteCp.fileName}`">
        <span>{{ siteCp.status404 }}</span>
      </a>
    </td>
    <td v-case="'errorLink'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'status410' }">
      <a @click.prevent="$router.push(`/checkedlink/status410/${siteCp.fileName}`)" :href="`/checkedlink/status410/${siteCp.fileName}`">
        <span>{{ siteCp.status410 }}</span>
      </a>
    </td>
    <td v-case="'errorLink'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'status499' }">
      <a @click.prevent="$router.push(`/checkedlink/status499/${siteCp.fileName}`)" :href="`/checkedlink/status499/${siteCp.fileName}`">
        <span>{{ siteCp.status499 }}</span>
      </a>
    </td>
    <td v-case="'errorLink'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'status500' }">
      <a @click.prevent="$router.push(`/checkedlink/status500/${siteCp.fileName}`)" :href="`/checkedlink/status500/${siteCp.fileName}`">
        <span>{{ siteCp.status500 }}</span>
      </a>
    </td>
    <td v-case="'errorLink'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'status503' }">
      <a @click.prevent="$router.push(`/checkedlink/status503/${siteCp.fileName}`)" :href="`/checkedlink/status503/${siteCp.fileName}`">
        <span>{{ siteCp.status503 }}</span>
      </a>
    </td>
    <td v-case="'errorLink'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'status520' }">
      <a @click.prevent="$router.push(`/checkedlink/status520/${siteCp.fileName}`)" :href="`/checkedlink/status520/${siteCp.fileName}`">
        <span>{{ siteCp.status520 }}</span>
      </a>
    </td>
    <td v-case="'errorLink'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'statusIn500To599' }">
      <a @click.prevent="$router.push(`/checkedlink/statusIn500To599/${siteCp.fileName}`)" :href="`/checkedlink/statusIn500To599/${siteCp.fileName}`">
        <span>{{ siteCp.statusIn500To599 }}</span>
      </a>
    </td>
    <td v-case="'errorLink'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'statusIn400To499' }">
      <a @click.prevent="$router.push(`/checkedlink/statusIn400To499/${siteCp.fileName}`)" :href="`/checkedlink/statusIn400To499/${siteCp.fileName}`">
        <span>{{ siteCp.statusIn400To499 }}</span>
      </a>
    </td>
    <td v-case="'errorLink'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'urlWithHashMark' }">
      <a @click.prevent="$router.push(`/checkedlink/urlWithHashMark/${siteCp.fileName}`)" :href="`/checkedlink/urlWithHashMark/${siteCp.fileName}`">
        <span>{{ siteCp.urlWithHashMark }}</span>
      </a>
    </td>
    <td v-case="'errorLink'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'aTagWithoutAnchor' }">
      <a @click.prevent="$router.push(`/checkedlink/aTagWithoutAnchor/${siteCp.fileName}`)" :href="`/checkedlink/aTagWithoutAnchor/${siteCp.fileName}`">
        <span>{{ siteCp.aTagWithoutAnchor }}</span>
      </a>
    </td>
    <td v-case="'errorLink'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'urlWithErrorChar' }">
      <a @click.prevent="$router.push(`/checkedlink/urlWithErrorChar/${siteCp.fileName}`)" :href="`/checkedlink/urlWithErrorChar/${siteCp.fileName}`">
        <span>{{ siteCp.urlWithErrorChar }}</span>
      </a>
    </td>
    <td v-case="'errorLink'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'webArchiveLink' }">
      <a @click.prevent="$router.push(`/checkedlink/webArchiveLink/${siteCp.fileName}`)" :href="`/checkedlink/webArchiveLink/${siteCp.fileName}`">
        <span>{{ siteCp.webArchiveLink }}</span>
      </a>
    </td>
    <td v-case="'errorLink'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'netlinkingLink' }">
      <a @click.prevent="$router.push(`/checkedlink/netlinkingLink/${siteCp.fileName}`)" :href="`/checkedlink/netlinkingLink/${siteCp.fileName}`">
        <span>{{ siteCp.netlinkingLink }}</span>
      </a>
    </td>
    <td v-case="'errorLink'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'trgoogLink' }">
      <a @click.prevent="$router.push(`/checkedlink/trgoogLink/${siteCp.fileName}`)" :href="`/checkedlink/trgoogLink/${siteCp.fileName}`">
        <span>{{ siteCp.trgoogLink }}</span>
      </a>
    </td>
    <td v-case="'errorLink'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'domainWithPort' }">
      <a @click.prevent="$router.push(`/checkedlink/domainWithPort/${siteCp.fileName}`)" :href="`/checkedlink/domainWithPort/${siteCp.fileName}`">
        <span>{{ siteCp.domainWithPort }}</span>
      </a>
    </td>
    <td v-case="'errorLink'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'domainError' }">
      <a @click.prevent="$router.push(`/checkedlink/domainError/${siteCp.fileName}`)" :href="`/checkedlink/domainError/${siteCp.fileName}`">
        <span>{{ siteCp.domainError }}</span>
      </a>
    </td>
    <td v-case="'errorLink'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'timeoutError' }">
      <a @click.prevent="$router.push(`/checkedlink/timeoutError/${siteCp.fileName}`)" :href="`/checkedlink/timeoutError/${siteCp.fileName}`">
        <span>{{ siteCp.timeoutError }}</span>
      </a>
    </td>
    <td v-case="'errorLink'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'unknownFileError' }">
      <a @click.prevent="$router.push(`/checkedlink/unknownFileError/${siteCp.fileName}`)" :href="`/checkedlink/unknownFileError/${siteCp.fileName}`">
        <span>{{ siteCp.unknownFileError }}</span>
      </a>
    </td>
    <td v-case="'errorLink'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'unknownError' }">
      <a @click.prevent="$router.push(`/checkedlink/unknownError/${siteCp.fileName}`)" :href="`/checkedlink/unknownError/${siteCp.fileName}`">
        <span>{{ siteCp.unknownError }}</span>
      </a>
    </td>
    <td v-case="'errorLink'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'requestNullError' }">
      <a @click.prevent="$router.push(`/checkedlink/requestNullError/${siteCp.fileName}`)" :href="`/checkedlink/requestNullError/${siteCp.fileName}`">
        <span>{{ siteCp.requestNullError }}</span>
      </a>
    </td>
    <td v-case="'errorLink'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'otherCases' }">
      <a @click.prevent="$router.push(`/checkedlink/otherCases/${siteCp.fileName}`)" :href="`/checkedlink/otherCases/${siteCp.fileName}`">
        <span>{{ siteCp.otherCases }}</span>
      </a>
    </td>
    <td v-case="'postKeywords'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'postLgSvKw' }">
      <a class="has-text-danger" @click.prevent="$router.push(`/postKeywords/postLgSvKw/${siteCp.fileName}`)" :href="`/postKeywords/postLgSvKw/${siteCp.fileName}`">
        <span>{{ siteCp.postLgSvKw }}</span>
      </a>
    </td>
    <td v-case="'postKeywords'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'postLgKw' }">
      <a class="has-text-danger" @click.prevent="$router.push(`/postKeywords/postLgKw/${siteCp.fileName}`)" :href="`/postKeywords/postLgKw/${siteCp.fileName}`">
        <span>{{ siteCp.postLgKw }}</span>
      </a>
    </td>
    <td v-case="'postKeywords'" class="has-text-centered nowrap" :class="{ 'has-background-link-light': sort === 'postCrawl' }">
      <a @click.prevent="$router.push(`/postKeywords/-/${siteCp.fileName}`)" :href="`/postKeywords/-/${siteCp.fileName}`">
        <span>{{ siteCp.postCrawl }}</span>
      </a>
    </td>
    <td v-case="'homePageStat'">
      <div class="field mb-0 is-grouped">
        <p class="control">
          <a class="button is-light is-link is-outlined is-small" :class="{ 'is-loading': isLoadingCP }" @click.prevent="crawlHomePage(siteCp.fileName, siteCp.firstUrl)">
            <span>{{ l("Resc. HP") }}</span>
          </a>
        </p>
      </div>
    </td>
  </tr>
</template>

<script>
import axios from "axios";
import { waitFor } from "../sharedfn.js";

export default {
  name: "SiteListDetail",
  props: {
    site: Object,
    extraCol: String,
    showInputUrl: Boolean,
    queueNb: Number,
    currLine: String,
    selectedItemsMap: Map,
    sort: String,
  },
  data() {
    return {
      siteCp: {},
      checked: false,
      isLoadingCP: false,
      cutCatNames: true,
    };
  },

  created() {
    this.siteCp = this.site;
  },

  watch: {
    selectedItemsMap: {
      handler: function (newVal) {
        if (newVal.has(this.siteCp.fileName)) {
          this.checked = true;
        } else {
          this.checked = false;
        }
      },
    },
  },

  methods: {
    selectItem(fileName, url) {
      if (this.checked) {
        this.$emit("addSelectedItems", [fileName, url]);
      } else {
        this.$emit("removeSelectedItems", fileName);
      }
    },

    async crawlHomePage(fileName, url) {
      this.isLoadingCP = true;
      try {
        await axios.get(`${this.apiInterface.qcrawl}?url=${encodeURIComponent(url)}&type=homepage`);
        await this.updateQueue();
        while (Object.keys((await axios.get(`${this.apiInterface.qget}?url=${encodeURIComponent(url)}&type=homepage`)).data).length !== 0) {
          await waitFor(3000);
        }
        await this.updateQueue();
        await this.refreshListRow(fileName);
      } catch (err) {
        console.log(err.message);
      }
      this.isLoadingCP = false;
    },

    updateCurrLine(fileName) {
      this.$emit("update:currLine", fileName);
    },

    async refreshListRow(fileName) {
      try {
        await axios.get(`${this.apiInterface.refresh}`);
        const response = await axios.get(`${this.apiInterface.sitelistrow}?file=${fileName}`);
        this.siteCp = response.data ?? {};
      } catch (err) {
        console.log(err.message);
      }
    },

    async updateQueue() {
      try {
        const response = await axios.get(`${this.apiInterface.qall}`);
        const resultsArray = response.data.results ?? [];
        this.$emit("update:queueNb", resultsArray.length);
      } catch (err) {
        console.log(err.message);
      }
    },
  },
};
</script>

<style></style>
