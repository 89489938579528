<template>
  <div class="modal" :class="{ 'is-active': showModal }">
    <div class="modal-background" @click="showModal = false"></div>
    <div class="modal-content has-background-white py-5 px-5">
      <h3 class="title mb-5 is-5">{{ l("Add a new Website to the Crawler queue") }}:</h3>
      <form @submit.prevent="addNewSite">
        <div class="field">
          <label class="label" for="newsite">{{ l("Homepage URL") }}</label>
          <div class="control">
            <input class="input" type="url" v-model="newSite" name="newsite" id="newsite" placeholder="https://example.com" pattern="http(s)?://.*" required />
          </div>
        </div>
        <div class="field is-grouped is-grouped-centered">
          <p class="control">
            <input class="button is-success" type="submit" :class="{ 'is-loading': isLoadingAd }" :value="l('Add')" />
          </p>
          <p class="control">
            <a class="button is-light" @click.prevent="showModal = false">{{ l("Cancel") }}</a>
          </p>
        </div>
      </form>
    </div>
    <button class="modal-close is-large" aria-label="close" @click.prevent="showModal = false">
      {{ l("Cancel") }}
    </button>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SiteListTopAdd",
  props: {
    displayAddModal: Boolean,
    queue: Number,
  },

  data() {
    return {
      isLoadingAd: false,
      newSite: "",
    };
  },

  computed: {
    showModal: {
      set(newVal) {
        this.$emit("update:displayAddModal", newVal);
      },
      get() {
        return this.displayAddModal;
      },
    },
  },

  methods: {
    async addNewSite() {
      this.isLoadingAd = true;
      try {
        await axios.get(`${this.apiInterface.qcrawl}?url=${encodeURIComponent(this.newSite)}&type=site`);
        this.$emit("update:queue", this.queue + 1);
      } catch (err) {
        console.log(err.message);
      }
      this.isLoadingAd = false;
      this.showModal = false;
    },
  },
};
</script>

<style></style>
