<template>
  <div>
    <div>
      <a class="cut-text max-width-800 tag is-info is-light is-medium is-justify-content-left" target="_blank" :href="`http://qsssgl.com/?url=${detail.url}`">{{ detail.title }} </a>
      <a class="button is-danger is-small is-pulled-right" @click.prevent="crawlPage(detail.url)" :class="{ 'is-loading': isLoading }">{{ l("Rescan") }} </a>
    </div>
    <table class="table is-striped is-bordered mt-2 is-fullwidth">
      <thead>
        <tr>
          <th class="has-text-centered width-20">{{ l("Error Type") }}</th>
          <th class="has-text-centered width-40">{{ l("Link") }}</th>
          <th class="has-text-centered width-20">{{ l("Anchor") }}</th>
          <th class="has-text-centered width-20">{{ l("Status") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(error, index) in detail.errorList" :key="index">
          <td>
            {{ l(error.errorType) }} <span v-if="error.errorType === 'doubleInternLink' || error.errorType === 'doubleExternLink'"> ({{ error.occur }})</span>
          </td>
          <td>
            <a class="cut-text max-width-400" target="_blank" :href="`http://qsssgl.com/?url=${error.url}`">
              {{ error.title }}
            </a>
          </td>
          <td>
            <span class="cut-text max-width-200">{{ error.anchor }}</span>
          </td>
          <td>
            <span class="cut-text max-width-200">{{ error.status }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";
import { waitFor } from "../sharedfn.js";

export default {
  name: "CheckedLinkListDetail",
  props: {
    detail: Object,
  },
  data() {
    return {
      isLoading: false,
    };
  },

  methods: {
    async crawlPage(url) {
      this.isLoading = true;
      try {
        await axios.get(`${this.apiInterface.qcrawl}?url=${encodeURIComponent(url)}&type=page`);
        while (Object.keys((await axios.get(`${this.apiInterface.qget}?url=${encodeURIComponent(url)}&type=page`)).data).length !== 0) {
          await waitFor(3000);
        }
        this.$emit("refreshChekedLinkList");
      } catch (err) {
        console.log(err.message);
      }
      this.isLoading = false;
    },
  },
};
</script>

<style></style>
