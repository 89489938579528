<template>
  <div class="noresult section">
    <div class="column is-6 is-offset-3">
      <div class="box">
        <h5 class="title is-5 my-5 has-text-centered">
          <p v-show="message">{{ message }}</p>
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoResult",
  props: {
    message: String,
  },
};
</script>

<style></style>
