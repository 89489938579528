<template>
  <div>
    <table class="table is-striped is-bordered mt-2 is-fullwidth">
      <caption class="subtitle is-size-5 py-1 mb-0" :class="{ 'has-background-success-light': type === 'ok', 'has-background-danger-light': type === 'error' }">
        {{
          caption
        }}
        ({{
          detail.length
        }})
      </caption>
      <thead v-if="detail.length">
        <tr>
          <th v-if="type === 'error'" class="has-text-centered width-20">{{ l("Error Type") }}</th>
          <th class="has-text-centered width-40">{{ l("Link") }}</th>
          <th class="has-text-centered width-20">{{ l("Anchor") }}</th>
          <th class="has-text-centered width-20">{{ l("Status") }}</th>
        </tr>
      </thead>
      <tbody v-if="detail.length">
        <tr v-for="(entry, index) in detail" :key="index">
          <td v-if="type === 'error'">
            {{ l(entry.errorType) }} <span v-if="entry.errorType === 'doubleInternLink' || entry.errorType === 'doubleExternLink'"> ({{ entry.occur }})</span>
          </td>
          <td>
            <a class="cut-text max-width-400" target="_blank" :href="`http://qsssgl.com/?url=${entry.url}`">
              {{ entry.title }}
            </a>
          </td>
          <td>
            <span class="cut-text max-width-200">{{ entry.anchor }}</span>
          </td>
          <td>
            <span class="cut-text max-width-200">{{ entry.status }}</span>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td class="has-text-centered">{{ l("No Links") }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "HpLinkListLangDetail",
  props: {
    detail: Array,
    type: String,
    caption: String,
  },
};
</script>

<style></style>
