<template>
  <div v-if="active" class="loading section">
    <div class="column is-6 is-offset-3">
      <div class="box">
        <div class="loader-wrapper">
          <div class="loader is-loading"></div>
        </div>
        <h5 class="title is-5 mt-5 has-text-centered">
          <p v-show="message">{{ message }}</p>
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingSpinner",
  props: {
    active: Boolean,
    message: String,
  },
};
</script>

<style>
.loader-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}
.loader-wrapper .loader {
  height: 40px;
  width: 40px;
}
</style>
