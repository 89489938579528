<template>
  <div class="container">
    <div class="datagrid-responsive">
      <table class="table is-striped is-bordered mt-2 is-fullwidth">
        <thead>
          <tr>
            <th>{{ l("Internal Links") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div>
                <a class="cut-text max-width-800 tag is-info is-light is-medium is-justify-content-left" target="_blank" :href="`http://qsssgl.com/?url=${siteInfo.url}`">{{ siteInfo.title }} </a>
              </div>
              <LoadingSpinner :active="loaderActive" :message="l('Loading data...')" />
              <div
                v-show="
                  postsInBoth.length ||
                  postsInWpApiOnly.length ||
                  postsInCrawlerOnly.length ||
                  categoriesInBoth.length ||
                  categoriesInWpApiOnly.length ||
                  categoriesInCrawlerOnly.length ||
                  pagesInBoth.length ||
                  pagesInWpApiOnly.length ||
                  pagesInCrawlerOnly.length
                "
              >
                <SubLinkListDetail v-if="postsInBoth.length" :detail="postsInBoth" type="ok" :caption="`${l('Posts')} (${l('both')})`" />
                <SubLinkListDetail v-if="postsInWpApiOnly.length" :detail="postsInWpApiOnly" type="error" :caption="`${l('Posts')} (${l('only api')})`" />
                <SubLinkListDetail v-if="postsInCrawlerOnly.length" :detail="postsInCrawlerOnly" type="error" :caption="`${l('Posts')} (${l('only crawler')})`" />
                <SubLinkListDetail v-if="categoriesInBoth.length" :detail="categoriesInBoth" type="ok" :caption="`${l('Categories')} (${l('both')})`" />
                <SubLinkListDetail v-if="categoriesInWpApiOnly.length" :detail="categoriesInWpApiOnly" type="error" :caption="`${l('Categories')} (${l('only api')})`" />
                <SubLinkListDetail v-if="categoriesInCrawlerOnly.length" :detail="categoriesInCrawlerOnly" type="error" :caption="`${l('Categories')} (${l('only crawler')})`" />
                <SubLinkListDetail v-if="pagesInBoth.length" :detail="pagesInBoth" type="ok" :caption="`${l('Pages')} (${l('both')})`" />
                <SubLinkListDetail v-if="pagesInWpApiOnly.length" :detail="pagesInWpApiOnly" type="error" :caption="`${l('Pages')} (${l('only api')})`" />
                <SubLinkListDetail v-if="pagesInCrawlerOnly.length" :detail="pagesInCrawlerOnly" type="error" :caption="`${l('Pages')} (${l('only crawler')})`" />
              </div>
              <div
                v-show="
                  !(
                    postsInBoth.length ||
                    postsInWpApiOnly.length ||
                    postsInCrawlerOnly.length ||
                    categoriesInBoth.length ||
                    categoriesInWpApiOnly.length ||
                    categoriesInCrawlerOnly.length ||
                    pagesInBoth.length ||
                    pagesInWpApiOnly.length ||
                    pagesInCrawlerOnly.length
                  ) && !loaderActive
                "
              >
                <NoResult :message="l('No results')" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import loaderMixin from "../mixins/loader.js";
import SubLinkListDetail from "./SubLinkList_Detail.vue";
import LoadingSpinner from "./LoadingSpinner.vue";
import NoResult from "./NoResult.vue";

export default {
  name: "SubLinkList",
  mixins: [loaderMixin],
  components: {
    SubLinkListDetail,
    LoadingSpinner,
    NoResult,
  },
  data() {
    return {
      siteInfo: {},
      postsInBoth: [],
      postsInWpApiOnly: [],
      postsInCrawlerOnly: [],
      categoriesInBoth: [],
      categoriesInWpApiOnly: [],
      categoriesInCrawlerOnly: [],
      pagesInBoth: [],
      pagesInWpApiOnly: [],
      pagesInCrawlerOnly: [],
    };
  },

  mounted() {
    this.SubLinkList();
  },

  methods: {
    async SubLinkList() {
      this.showLoader();
      try {
        const apiUrl = `${this.apiInterface.sublink}?file=${this.$route.params.file}`;
        const response = await axios.get(apiUrl);
        const sublinks = response.data ?? {};
        this.siteInfo = sublinks?.siteInfo ?? [];
        this.postsInBoth = sublinks?.postsInBoth ?? [];
        this.postsInWpApiOnly = sublinks?.postsInWpApiOnly ?? [];
        this.postsInCrawlerOnly = sublinks?.postsInCrawlerOnly ?? [];
        this.categoriesInBoth = sublinks?.categoriesInBoth ?? [];
        this.categoriesInWpApiOnly = sublinks?.categoriesInWpApiOnly ?? [];
        this.categoriesInCrawlerOnly = sublinks?.categoriesInCrawlerOnly ?? [];
        this.pagesInBoth = sublinks?.pagesInBoth ?? [];
        this.pagesInWpApiOnly = sublinks?.pagesInWpApiOnly ?? [];
        this.pagesInCrawlerOnly = sublinks?.pagesInCrawlerOnly ?? [];
      } catch (err) {
        console.log(err.message);
      }
      this.hideLoader();
    },
  },
};
</script>

<style></style>
