var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"columns"},[_c('div',{staticClass:"column column is-8-tablet is-6-desktop is-4-fullhd is-offset-0-tablet is-offset-2-desktop is-offset-4-fullhd"},[_c('nav',{directives:[{name:"show",rawName:"v-show",value:(_vm.stVals.length > 1),expression:"stVals.length > 1"}],staticClass:"pagination is-centered",attrs:{"role":"navigation"}},[_c('a',{staticClass:"pagination-previous",attrs:{"disabled":_vm.currSt === _vm.stVals[0]},on:{"click":function($event){$event.preventDefault();_vm.currSt -= _vm.amount}}},[_vm._v(_vm._s(_vm.l("Previous")))]),_c('a',{staticClass:"pagination-next",attrs:{"disabled":_vm.currSt === _vm.stVals[_vm.stVals.length - 1]},on:{"click":function($event){$event.preventDefault();_vm.currSt += _vm.amount}}},[_vm._v(_vm._s(_vm.l("Next")))]),_c('ul',{staticClass:"pagination-list"},[_c('li',[_c('a',{staticClass:"pagination-link",class:{ 'is-current': 0 === _vm.currSt },on:{"click":function($event){$event.preventDefault();_vm.currSt = 0}}},[_vm._v("1")])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDotsL),expression:"showDotsL"}]},[_c('span',{staticClass:"pagination-ellipsis"},[_vm._v("…")])]),_vm._l((_vm.stVals),function(val,index){return _c('li',{key:val},[((_vm.showDotsL = _vm.currSt > _vm.stVals[2] ? true : false))?void 0:_vm._e(),((_vm.showDotsR = _vm.currSt < _vm.stVals[_vm.stVals.length - 3] ? true : false))?void 0:_vm._e(),(
              index !== 0 &&
              index !== _vm.stVals.length - 1 &&
              ([_vm.currSt - _vm.amount, _vm.currSt, _vm.currSt + +_vm.amount].includes(val) ||
                (_vm.currSt === 0 && [_vm.currSt + +_vm.amount, _vm.currSt + 2 * _vm.amount, _vm.currSt + 3 * _vm.amount].includes(val)) ||
                (_vm.currSt === 0 + +_vm.amount && [_vm.currSt + 2 * _vm.amount].includes(val)) ||
                (_vm.currSt === _vm.stVals[_vm.stVals.length - 1] - _vm.amount && [_vm.currSt - 2 * _vm.amount].includes(val)) ||
                (_vm.currSt === _vm.stVals[_vm.stVals.length - 1] && [_vm.currSt - _vm.amount, _vm.currSt - 2 * _vm.amount, _vm.currSt - 3 * _vm.amount].includes(val)))
            )?_c('a',{staticClass:"pagination-link",class:{ 'is-current': val === _vm.currSt },on:{"click":function($event){$event.preventDefault();_vm.currSt = val}}},[_vm._v(_vm._s(index + 1))]):_vm._e()],2)}),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDotsR),expression:"showDotsR"}]},[_c('span',{staticClass:"pagination-ellipsis"},[_vm._v("…")])]),_c('li',[_c('a',{staticClass:"pagination-link",class:{ 'is-current': _vm.stVals[_vm.stVals.length - 1] === _vm.currSt },on:{"click":function($event){$event.preventDefault();_vm.currSt = _vm.stVals[_vm.stVals.length - 1]}}},[_vm._v(_vm._s(_vm.stVals.length))])])],2)])]),_c('div',{staticClass:"column is-flex is-align-items-center is-justify-content-right column is-flex is-align-items-center is-justify-content-right is-4-tablet is-2-desktop is-offset-0-tablet is-offset-2-desktop"},[_c('span',{staticClass:"title is-size-5"},[_vm._v(_vm._s(_vm.l("Total"))+": "+_vm._s(_vm.totalItemCount))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }