export default {
  methods: {
    l(str, domain = "global") {
      const global = {
        homePageStat: "Accueil Stat",
        internalLink: "Liens Internes",
        errorLink: "Liens Erronés",
        postKeywords: "Mots-clés Articles",
        both: "Vues par les deux WpApi/Crawler",
        "only api": "Vues seulement par WpApi",
        "only crawler": "Vues seulement par Crawler",
        Home: "Accueil",
        inCategory: "Catégorie",
        inMenu: "Menu",
        "Anchor / Name": "Ancre / Nom",
        "N. Posts": "N. Articles",
        "Rescan HP": "Rescanner HP",
        "Server error": "Erreur serveur",
        "Authentication failed": "Echec d'authentification",
        "Enter your password": "Entrez votre mot de passe",
        Password: "Mot de passe",
        Connexion: "Connexion",
        Login: "Identifiant",
        Logout: "Déconnexion",
        statusError: "Statut 400-599",
        NoCategory: "Non Catégorie",
        "Internal Links Error": "Liens Internes Erreur",
        "External Links Error": "Liens Externes Erreur",
        "Internal Links Ok": "Liens Internes Ok",
        "External Links Ok": "Liens Externes Ok",
        "Menu Links Error": "Liens Menu Erreur",
        "Menu Links Ok": "Liens Menu Ok",
        "Menu Links": "Liens Menu",
        "Home Page Links": "Liens Page d'Accueil",
        "Home I/E": "Accueil I/E",
        Clear: "Vider",
        Close: "Fermer",
        "Dele.": "Supp.",
        "Resc.": "Resc.",
        "Inter.": "Inter.",
        "Erro.": "Erro.",
        "Removal of Website": "Suppression de données collectées",
        "Add new websites": "Ajout de nouveaux Site Web",
        "No new websites": "Pas de nouveaux Site Web à ajouter",
        "Add a new Website to the Crawler queue": "Ajouter un nouveau Site Web à la pile du Crawler",
        "Empty Queue.": "Pile vide.",
        Cancel: "Annuler",
        "Manage queue (in execution)": "Gérer la pile (en cours d'exécution)",
        "Homepage URL": "URL de la page d'accueil",
        URL: "URL",
        Type: "Type",
        Added: "Ajout",
        Start: "Début",
        "Loading data...": "Chargement de données...",
        "Internal Links": "Liens Internes",
        "No results": "Pas de résultats.",
        "Wrong Links": "Liens Erronés",
        "Page Not Found": "Page Introuvable",
        Total: "Total",
        Next: "Suivant",
        Previous: "Précédent",
        Search: "Rechercher",
        Queue: "Pile",
        Refresh: "Actualiser",
        Extra: "Extra",
        Nb: "Nb",
        Origin: "Origine",
        Rescan: "Rescanner",
        Remove: "Supprimer",
        Add: "Ajouter",
        Import: "Importer",
        Date: "Date",
        Actions: "Actions",
        "Error Type": "Type d'erreur",
        Link: "Lien",
        Anchor: "Ancre",
        State: "État",
        Status: "Statut",
        doubleInternLink: "Liens internes en double",
        doubleExternLink: "Liens externes en double",
        intranetDomainError: "Domaine intranet avec erreur",
        status400: "400 Requête erronée",
        status401: "401 Authentification non valide",
        status403: "403 Autorisation refusée",
        status404: "404 Ressource non disponible",
        status410: "410 Ressource n'est plus disponible",
        status499: "499 Le client a fermé la connexion",
        status500: "500 Problème inattendu coté serveur",
        status503: "503 Service non disponible",
        status520: "520 Erreur inconnue",
        statusIn500To599: "Autre statut entre 500-599",
        statusIn400To499: "Autre statut entre 400-498",
        urlWithHashMark: "Lien avec (#)",
        aTagWithoutAnchor: "Lien sans ancre",
        urlWithErrorChar: "Lien avec erreur d'écriture",
        webArchiveLink: "Web.archive.org",
        netlinkingLink: "Netlinking.fr / Noesys.fr / Missions-freelance.com / Qsssgl.com",
        trgoogLink: "Google translate",
        domainWithPort: "Domaine suffixé par un port",
        domainError: "Domaine erreur",
        timeoutError: "Timeout erreur",
        unknownFileError: "Fichier inconnu",
        unknownError: "Erreur inconnue",
        requestNullError: "Réponse incorrecte",
        otherCases: "Autre erreur",
        Posts: "Articles",
        Categories: "Categories",
        Pages: "Pages",
      };

      const menu = {
        Actions: "Actions",
        categoriesNames: "Noms Cat",
        categoriesNb: "N. Cat",
        hpExterLink: "HP Ext.",
        hpInterLink: "HP Int.",
        hpLoadingTime: "HP Temps",
        hpMenuLinksCombo: "Menu",
        hpMenuLinksError: "!Menu",
        hpStatus: "HP Statut",
        hpTotalError: "HP Err",
        hpTotalLink: "HP Total",
        pageLangOth: "!P. Lang",
        pageNoCrawl: "!Page",
        pageRatio: "Page",
        passPhrase: "P. Ph",
        postLangOth: "!A. Lang",
        postNoCrawl: "!Article",
        postNoCategory: "!A. Cat",
        postInUncategorized: "!A. NonClas.",
        postRatio: "Article",
        siteEtat: "Etat",
        siteType: "Type",
        themeName: "Theme",
        urlUnicodeAccentLess: "Sites",
        verifySSL: "SSl",
        wpApiSubLinkCount: "Api",
        subLinkCount: "Inter.",
        errorSum: "Tout",
        doubleInternLink: "Dbl. Int.",
        doubleExternLink: "Dbl. Ext.",
        intranetDomainError: "Err. Intra.",
        status400: "400",
        status401: "401",
        status403: "403",
        status404: "404",
        status410: "410",
        status499: "499",
        status500: "500",
        status503: "503",
        status520: "520",
        statusIn500To599: "5**",
        statusIn400To499: "4**",
        urlWithHashMark: "(#)",
        aTagWithoutAnchor: "No Ancre",
        urlWithErrorChar: "Err. Char",
        webArchiveLink: "Web A.",
        netlinkingLink: "Netlink.",
        trgoogLink: "Goog. Tr.",
        domainWithPort: ":Port",
        domainError: "Domaine",
        timeoutError: "Timeout",
        unknownFileError: "Fich. Inc.",
        unknownError: "Err. Inc.",
        requestNullError: "Rq. Null",
        otherCases: "Autre",
        title: "Titre",
        inCrawler: "Crawl",
        titlePost: "Article",
        categories: "Categories",
        language: "Langue",
        image: "Image",
        titlePage: "Page",
        postLgSvKw: "A. MCL+",
        postLgKw: "A. MCL",
        postCrawl: "A. Crawl",
      };

      const menuHover = {
        Actions: "Actions",
        categoriesNames: "Noms Cat",
        categoriesNb: "N. Cat",
        hpExterLink: "HP Ext.",
        hpInterLink: "HP Int.",
        hpLoadingTime: "HP Temps",
        hpMenuLinksCombo: "Menu",
        hpMenuLinksError: "!Menu",
        hpStatus: "HP Statut",
        hpTotalError: "HP Err",
        hpTotalLink: "HP Total",
        pageLangOth: "!P. Lang",
        pageNoCrawl: "!Page",
        pageRatio: "Page",
        passPhrase: "P. Ph",
        postLangOth: "!A. Lang",
        postNoCrawl: "!Article",
        postNoCategory: "Article sans categorie",
        postInUncategorized: "Article dans la categorie 'Non classifié(e)'",
        postRatio: "Article",
        siteEtat: "Etat",
        siteType: "Type",
        themeName: "Theme",
        urlUnicodeAccentLess: "Sites",
        verifySSL: "SSl",
        wpApiSubLinkCount: "Api",
        subLinkCount: "Inter.",
        errorSum: "Total des erreurs",
        doubleInternLink: "Liens internes en double",
        doubleExternLink: "Liens externes en double",
        intranetDomainError: "Domaine intranet avec erreur",
        status400: "400 Requête erronée",
        status401: "401 Authentification non valide",
        status403: "403 Autorisation refusée",
        status404: "404 Ressource non disponible",
        status410: "410 Ressource n'est plus disponible",
        status499: "499 Le client a fermé la connexion",
        status500: "500 Problème inattendu coté serveur",
        status503: "503 Service non disponible",
        status520: "520 Erreur inconnue",
        statusIn500To599: "Autre statut entre 500-599",
        statusIn400To499: "Autre statut entre 400-498",
        urlWithHashMark: "Lien avec (#)",
        aTagWithoutAnchor: "Lien sans ancre",
        urlWithErrorChar: "Lien avec erreur d'écriture",
        webArchiveLink: "Web.archive.org",
        netlinkingLink: "Netlinking.fr / Noesys.fr / Missions-freelance.com / Qsssgl.com",
        trgoogLink: "Google translate",
        domainWithPort: "Domaine suffixé par un port",
        domainError: "Domaine erreur",
        timeoutError: "Timeout erreur",
        unknownFileError: "Fichier inconnu",
        unknownError: "Erreur inconnue",
        requestNullError: "Réponse incorrecte",
        otherCases: "Autre erreur",
        title: "Titre",
        inCrawler: "Crawl",
        titlePost: "Article",
        categories: "Categories",
        language: "Langue",
        image: "Image",
        titlePage: "Page",
        postLgSvKw: "Articles avec des plusieur mots-clés longues",
        postLgKw: "Articles avec des mots-clés longues",
        postCrawl: "Articles crawlés",
      };

      //Hack for eslint
      global;
      menu;
      menuHover;
      return eval(domain)[str] ?? str;
    },
  },
};
