<template>
  <div class="container mb-4">
    <div>
      <table class="table is-striped is-bordered mt-2 is-fullwidth">
        <thead>
          <tr>
            <th>{{ l("Home Page Links") }} ({{ hpLanguage }})</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div>
                <a class="cut-text max-width-800 tag is-info is-light is-medium is-justify-content-left" target="_blank" :href="`http://qsssgl.com/?url=${hpUrl}`">{{ hpUrlUnicode }} </a>
                <a v-if="hpUrl === firstUrl" class="button is-danger is-small is-pulled-right" @click.prevent="crawlPage(hpUrl)" :class="{ 'is-loading': isLoading }">{{ l("Rescan") }} </a>
              </div>
              <div v-show="interLinksOk.length || interLinksError.length || exterLinksOk.length || exterLinksError.length || menuLinksCombo.length">
                <HpLinkListLangDetail v-if="interLinksError.length" :detail="interLinksError" type="error" :caption="l('Internal Links Error')" />
                <HpLinkListLangDetail v-if="interLinksOk.length" :detail="interLinksOk" type="ok" :caption="l('Internal Links Ok')" />
                <HpLinkListLangDetail v-if="exterLinksError.length" :detail="exterLinksError" type="error" :caption="l('External Links Error')" />
                <HpLinkListLangDetail v-if="exterLinksOk.length" :detail="exterLinksOk" type="ok" :caption="l('External Links Ok')" />
                <HpLinkListLangMenu v-if="menuLinksCombo.length" :detail="menuLinksCombo" :error="menuLinksError" :caption="l('Menu Links')" />
              </div>
              <div v-show="!(interLinksOk.length || interLinksError.length || exterLinksOk.length || exterLinksError.length || menuLinksCombo.length) && !loaderActive">
                <NoResult :message="l('No results')" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { waitFor } from "../sharedfn.js";
import HpLinkListLangDetail from "./HpLinkList_Lang_Detail.vue";
import HpLinkListLangMenu from "./HpLinkList_Lang_Menu.vue";
import NoResult from "./NoResult.vue";

export default {
  name: "HpLinkList",
  components: {
    HpLinkListLangDetail,
    HpLinkListLangMenu,
    NoResult,
  },
  props: {
    loaderActive: Boolean,
    firstUrl: String,
    hpLinks: Object,
  },
  data() {
    return {
      isLoading: false,
      hpUrl: "",
      hpUrlUnicode: "",
      hpLanguage: "",
      interLinksOk: [],
      interLinksError: [],
      exterLinksOk: [],
      exterLinksError: [],
      menuLinksCombo: [],
      menuLinksError: [],
    };
  },

  mounted() {
    this.getHpLinkLang();
  },

  methods: {
    async getHpLinkLang() {
      this.hpUrl = this.hpLinks?.hpUrl ?? "";
      this.hpUrlUnicode = this.hpLinks?.hpUrlUnicode ?? "";
      this.hpLanguage = this.hpLinks?.hpLanguage ?? "";
      this.interLinksOk = this.hpLinks?.interLinksOk ?? [];
      this.interLinksError = this.hpLinks?.interLinksError ?? [];
      this.exterLinksOk = this.hpLinks?.exterLinksOk ?? [];
      this.exterLinksError = this.hpLinks?.exterLinksError ?? [];
      this.menuLinksCombo = this.hpLinks?.menuLinksCombo ?? [];
      this.menuLinksError = this.hpLinks?.menuLinksError ?? [];
    },

    async crawlPage(url) {
      this.isLoading = true;
      try {
        await axios.get(`${this.apiInterface.qcrawl}?url=${encodeURIComponent(url)}&type=homepage`);
        while (Object.keys((await axios.get(`${this.apiInterface.qget}?url=${encodeURIComponent(url)}&type=homepage`)).data).length !== 0) {
          await waitFor(3000);
        }
        await axios.get(`${this.apiInterface.qcrawl}?url=${encodeURIComponent(url)}&type=wpapi`);
        while (Object.keys((await axios.get(`${this.apiInterface.qget}?url=${encodeURIComponent(url)}&type=wpapi`)).data).length !== 0) {
          await waitFor(3000);
        }
        this.$emit("refreshHpLinkList");
      } catch (err) {
        console.log(err.message);
      }
      this.isLoading = false;
    },
  },
};
</script>

<style></style>
