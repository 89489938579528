<template>
  <div class="modal" :class="{ 'is-active': showModal }">
    <div class="modal-background" @click="showModal = false"></div>
    <div class="modal-content has-background-white py-5 px-5">
      <template v-if="counter > 0">
        <h3 class="title mb-3 is-5">{{ l("Add new websites") }} ({{ newSites.length }}):</h3>
        <div v-for="url in newSites" :key="url" class="field mb-0 mx-4">
          <span class="cut-text max-width-500 is-size-6">{{ url }}</span>
        </div>
        <div class="field is-grouped is-grouped-centered mt-4">
          <p class="control">
            <a class="button is-success" @click.prevent="addSites" :class="{ 'is-loading': isLoading }">
              {{ l("Add") }}<span class="ml-1 has-text-white">({{ counter }})</span>
            </a>
          </p>
          <p class="control">
            <a class="button is-light" @click.prevent="showModal = false">{{ l("Cancel") }}</a>
          </p>
        </div>
      </template>
      <template v-else>
        <h3 class="title mb-3 is-5 has-text-centered">{{ l("No new websites") }}</h3>
        <div class="field is-grouped is-grouped-centered mt-4">
          <p class="control">
            <a class="button is-info" @click.prevent="showModal = false">&nbsp;&nbsp;{{ l("Ok") }}&nbsp;&nbsp;</a>
          </p>
        </div>
      </template>
    </div>
    <button class="modal-close is-large" aria-label="close" @click.prevent="showModal = false">
      {{ l("Cancel") }}
    </button>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SiteListTopImport",
  props: {
    displayImportModal: Boolean,
    queue: Number,
    newSites: Array,
  },

  data() {
    return {
      isLoading: false,
      counter: this.newSites.length,
    };
  },

  computed: {
    showModal: {
      set(newVal) {
        this.$emit("update:displayImportModal", newVal);
      },
      get() {
        return this.displayImportModal;
      },
    },
  },

  methods: {
    async updateQueue() {
      try {
        const response = await axios.get(`${this.apiInterface.qall}`);
        const resultsArray = response.data.results ?? [];
        this.$emit("update:queue", resultsArray.length);
      } catch (err) {
        console.log(err.message);
      }
    },

    async addSites() {
      this.isLoading = true;
      try {
        for (const url of this.newSites) {
          await axios.get(`${this.apiInterface.qcrawl}?url=${encodeURIComponent(url)}&type=site`);
          this.counter--;
        }
      } catch (err) {
        console.log(err.message);
      }
      // It's necessary to use "wait" for $emit function
      await this.updateQueue();
      this.$parent.$emit("update:currLine", "-");
      this.$emit("clearNewSites");
      this.isLoading = false;
      this.showModal = false;
    },
  },
};
</script>

<style></style>
