<template>
  <div class="container">
    <div class="datagrid-responsive">
      <table class="table is-striped is-bordered mt-2 is-fullwidth">
        <thead>
          <tr>
            <th>{{ l("Post Keywords") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div>
                <a class="cut-text max-width-400 tag is-info is-light is-medium is-justify-content-left" target="_blank" :href="`http://qsssgl.com/?url=${siteInfo.url}`">{{ siteInfo.title }} </a>
              </div>
              <LoadingSpinner :active="loaderActive" :message="l('Loading data...')" />
              <div v-show="postKeywords.length">
                <table class="table is-striped is-bordered mt-2 is-fullwidth">
                  <thead>
                    <tr>
                      <th class="has-text-centered width-20">{{ l("Link") }}</th>
                      <th class="has-text-centered width-40">{{ l("Keywords") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <PostKeywordsListDetail v-for="detail in postKeywords" :detail="detail" :key="detail.id" />
                  </tbody>
                </table>
              </div>
              <div v-show="!postKeywords.length && !loaderActive">
                <NoResult :message="l('No results')" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import loaderMixin from "../mixins/loader.js";
import PostKeywordsListDetail from "./PostKeywordsList_Detail.vue";
import LoadingSpinner from "./LoadingSpinner.vue";
import NoResult from "./NoResult.vue";

export default {
  name: "PostKeywordsList",
  mixins: [loaderMixin],
  components: {
    PostKeywordsListDetail,
    LoadingSpinner,
    NoResult,
  },
  data() {
    return {
      siteInfo: {},
      postKeywords: [],
      preFilter: "-",
    };
  },

  created() {
    this.preFilter = this.$route.params.prefilter ?? "-";
  },

  mounted() {
    this.SubLinkList();
  },

  methods: {
    async SubLinkList() {
      this.showLoader();
      try {
        const apiUrl = `${this.apiInterface.postkeywords}?filter=${this.preFilter}&file=${this.$route.params.file}`;
        const response = await axios.get(apiUrl);
        const postKeywords = response.data ?? {};
        this.siteInfo = postKeywords?.siteInfo ?? [];
        this.postKeywords = postKeywords?.postKeywords ?? [];
      } catch (err) {
        console.log(err.message);
      }
      this.hideLoader();
    },
  },
};
</script>

<style></style>
