<template>
  <div class="container">
    <div class="datagrid-responsive">
      <LoadingSpinner :active="loaderActive" :message="l('Loading data...')" />
      <div v-show="checkedLinks.length">
        <table class="table is-striped is-bordered mt-2 is-fullwidth">
          <thead>
            <tr>
              <th>
                <div class="columns">
                  <div class="column">
                    <div class="buttons is-left">
                      {{ l("Wrong Links") }}
                      <a class="button is-light is-link is-outlined is-small is-rounded ml-2 mb-0" target="_blank" :href="`http://qsssgl.com/?url=${siteInfo.url}wp-login.php`">{{ l("Admin") }}</a>
                    </div>
                  </div>
                  <div class="column">
                    <div class="buttons is-right">
                      <a class="button is-link is-small is-rounded mr-0" :class="{ 'is-loading': isLoading }" @click.prevent="crawlSite()">
                        {{ l("Rescan Site") }}
                      </a>
                    </div>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="checkedLink in checkedLinks" :key="checkedLink.url">
              <td>
                <CheckedLinkListDetail :detail="checkedLink" @refreshChekedLinkList="onRefreshChekedLinkList" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-show="!checkedLinks.length && !loaderActive"><NoResult :message="l('No results')" /></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { waitFor } from "../sharedfn.js";
import loaderMixin from "../mixins/loader.js";
import LoadingSpinner from "./LoadingSpinner.vue";
import CheckedLinkListDetail from "./CheckedLinkList_Detail.vue";
import NoResult from "./NoResult.vue";

export default {
  name: "CheckedLinkList",
  mixins: [loaderMixin],
  components: {
    LoadingSpinner,
    CheckedLinkListDetail,
    NoResult,
  },
  data() {
    return {
      siteInfo: {},
      checkedLinks: [],
      preFilter: "-",
      isLoading: false,
    };
  },

  created() {
    this.preFilter = this.$route.params.prefilter ?? "-";
  },

  mounted() {
    this.getCheckedLinkList();
  },

  methods: {
    async getCheckedLinkList() {
      this.showLoader();
      try {
        const apiUrl = `${this.apiInterface.check}?filter=${this.preFilter}&file=${this.$route.params.file}`;
        const response = await axios.get(apiUrl);
        this.siteInfo = response.data?.siteInfo ?? {};
        this.checkedLinks = response.data?.classifiedErrorFiltredArray ?? [];
      } catch (err) {
        console.log(err.message);
      }
      this.hideLoader();
    },

    async crawlSite() {
      this.isLoading = true;
      try {
        await axios.get(`${this.apiInterface.qcrawl}?url=${encodeURIComponent(this.siteInfo?.url)}&type=site`);
        while (Object.keys((await axios.get(`${this.apiInterface.qget}?url=${encodeURIComponent(this.siteInfo?.url)}&type=site`)).data).length !== 0) {
          await waitFor(10000);
        }
        this.getCheckedLinkList();
      } catch (err) {
        console.log(err.message);
      }
      this.isLoading = false;
    },

    onRefreshChekedLinkList() {
      this.getCheckedLinkList();
    },
  },
};
</script>

<style></style>
