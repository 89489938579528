<template>
  <div>
    <PageListTop @clearSelectedItems="onClearSelectedItems" @refreshList="onRefreshList" :selectedItemsMap="selectedItemsMap" :currLine.sync="currLine" :siteInfo="siteInfo" />
    <div class="datagrid-responsive mb-4">
      <table class="table is-striped is-bordered mb-2 is-fullwidth">
        <thead>
          <tr>
            <th class="has-text-centered width-2">
              <div class="control">
                <input class="mt-6" v-model="checkAll" type="checkbox" />
              </div>
            </th>
            <TableHead :title="'title'" :sortKey="'title'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" />
            <TableHead :title="'inCrawler'" :sortKey="'inCrawler'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" />
            <TableHead :title="'titlePage'" :sortKey="'titlePage'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" />
            <TableHead :title="'language'" :sortKey="'language'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" />
          </tr>
        </thead>
        <tbody>
          <tr v-show="loaderActive">
            <td colspan="100%">
              <LoadingSpinner :active="loaderActive" :message="l('Loading data...')" />
            </td>
          </tr>
          <PageListDetail
            v-show="pages.length"
            v-for="page in pages"
            :key="page.id"
            @addSelectedItems="onAddSelectedItems"
            @removeSelectedItems="onRemoveSelectedItems"
            :currLine.sync="currLine"
            :page="page"
            :selectedItemsMap="selectedItemsMap"
          />
          <tr v-show="!pages.length && !loaderActive">
            <td colspan="100%">
              <div><NoResult :message="l('No results')" /></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import loaderMixin from "../mixins/loader.js";
import LoadingSpinner from "./LoadingSpinner.vue";
import NoResult from "./NoResult.vue";
import PageListTop from "./PageList_Top.vue";
import TableHead from "./TableHead.vue";
import PageListDetail from "./PageList_Detail.vue";

export default {
  name: "PageList",
  mixins: [loaderMixin],
  components: {
    LoadingSpinner,
    NoResult,
    PageListTop,
    TableHead,
    PageListDetail,
  },
  data() {
    return {
      totalItemCount: 0,
      sort: "inCrawler",
      sortOrd: "ASC",
      pages: [],
      currLine: 0,
      filter: "-",
      selectedItemsMap: new Map(),
      checkAll: false,
      siteInfo: {},
      preFilter: "-",
    };
  },

  created() {
    this.sort = this.$route.params.sort ?? "inCrawler";
    this.sortOrd = this.$route.params.sortord ?? "ASC";
    this.currLine = +(this.$route.params.currline ?? 0);
    this.filter = this.$route.params.filter ?? "-";
    this.preFilter = this.$route.params.prefilter ?? "-";
  },

  mounted() {
    this.getPageList();
  },

  watch: {
    params: {
      handler: function () {
        const newUrl = `/page/${this.preFilter}/${this.$route.params.file}/${this.sort}/${this.sortOrd}/${this.currLine}/${this.filter}`;
        if (this.$router.history.current.path !== newUrl) {
          this.$router.push(newUrl);
        }
      },
    },
    checkAll: {
      handler: function (newVal) {
        if (newVal) {
          this.selectedItemsMap = new Map();
          this.pages.forEach((obj) => {
            this.selectedItemsMap.set(obj.id, obj.url);
          });
        } else {
          this.selectedItemsMap = new Map();
        }
      },
    },
  },

  computed: {
    params() {
      return this.sort + this.sortOrd + this.filter;
    },
  },

  methods: {
    async getPageList() {
      this.pages = [];
      this.showLoader();
      try {
        const response = await axios.get(`${this.apiInterface.page}?file=${this.$route.params.file}`);
        this.siteInfo = response.data.siteInfo ?? {};
        this.pages = response.data.pagesAll ?? [];

        if (this.preFilter === "noCrawl") {
          this.pages = this.pages.filter((p) => p.inCrawler === 0);
        } else if (this.preFilter === "othLang") {
          this.pages = this.pages.filter((p) => p.language !== this.siteInfo?.languageDefault && !this.siteInfo?.languageOther.includes(p.language));
          //this.sort = "language"; // TOFIX double redirection
        }

        const filterArr = this.filter.split(":");
        if (filterArr?.length === 2) {
          const valReg = new RegExp(filterArr[1], "i");
          this.pages = this.pages.filter((p) => `${p[filterArr[0]]}`?.match(valReg));
        }

        const collator = new Intl.Collator(undefined, {
          numeric: true,
          sensitivity: "base",
        });

        this.pages = this.pages.sort((a, b) => {
          return this.sortOrd === "ASC" ? collator.compare(a[this.sort], b[this.sort]) : collator.compare(b[this.sort], a[this.sort]);
        });

        this.totalItemCount = this.pages.length ?? 0;
      } catch (err) {
        console.log(err.message);
      }
      this.hideLoader();
    },

    onRefreshList() {
      this.getPageList();
    },

    onAddSelectedItems([id, url]) {
      this.selectedItemsMap.set(id, url);
    },

    onRemoveSelectedItems(id) {
      this.selectedItemsMap.delete(id);
    },

    onClearSelectedItems(action) {
      if (action === "deleteAction") {
        this.pages = this.pages.filter((p) => !this.selectedItemsMap.has(p.id));
      }
      this.selectedItemsMap = new Map();
      this.checkAll = false;
    },
  },
};
</script>

<style></style>
