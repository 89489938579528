import Vue from "vue";
import VueRouter from "vue-router";
import VSwitch from "v-switch-case";

import App from "./App.vue";
import Login from "./components/Login.vue";
import SiteList from "./components/SiteList.vue";
import PostList from "./components/PostList.vue";
import PageList from "./components/PageList.vue";
import SubLinkList from "./components/SubLinkList.vue";
import PostKeywordsList from "./components/PostKeywordsList.vue";
import CheckedLinkList from "./components/CheckedLinkList.vue";
import HpLinkList from "./components/HpLinkList.vue";
import PageNotFound from "./components/PageNotFound.vue";

import translation from "./mixins/translation.js";

Vue.use(VSwitch);
Vue.use(VueRouter);

Vue.config.productionTip = false;

Vue.mixin({
  created: function () {
    const apiServer = `${location.protocol}//${location.hostname}${process.env.VUE_APP_PORT}`;
    this.apiInterface = {
      login: `${apiServer}/api/login`,
      logout: `${apiServer}/api/logout`,
      sitelist: `${apiServer}/api/sitelist`,
      sitelistrow: `${apiServer}/api/sitelistrow`,
      importnewsites: `${apiServer}/api/importnewsites`,
      postkeywords: `${apiServer}/api/postkeywords`,
      sublink: `${apiServer}/api/sublink`,
      post: `${apiServer}/api/post`,
      page: `${apiServer}/api/page`,
      wpdeletepost: `${apiServer}/api/wpdeletepost`,
      check: `${apiServer}/api/check`,
      hplink: `${apiServer}/api/hplink`,
      delete: `${apiServer}/api/delete`,
      refresh: `${apiServer}/api/refresh`,
      qcrawl: `${apiServer}/q/crawl`,
      qget: `${apiServer}/q/get`,
      qdelete: `${apiServer}/q/delete`,
      qclear: `${apiServer}/q/clear`,
      qall: `${apiServer}/q/all`,
    };
  },
  ...translation,
});

const routes = [
  {
    name: "Index",
    path: "/",
    component: SiteList,
  },
  {
    name: "Login",
    path: "/login",
    component: Login,
  },
  {
    name: "SubLinkList",
    path: "/sublink/:file",
    component: SubLinkList,
  },
  {
    name: "PostKeywordsList",
    path: "/postkeywords/:prefilter/:file",
    component: PostKeywordsList,
  },
  {
    name: "PostList",
    path: "/post/:prefilter/:file/:sort?/:sortord?/:currline?/:filter?",
    component: PostList,
  },
  {
    name: "PageList",
    path: "/page/:prefilter/:file/:sort?/:sortord?/:currline?/:filter?",
    component: PageList,
  },
  {
    name: "CheckedLinkList",
    path: "/checkedlink/:prefilter/:file",
    component: CheckedLinkList,
  },
  {
    name: "HpLinkList",
    path: "/homepagelink/:file",
    component: HpLinkList,
  },
  {
    name: "SiteList",
    path: "/:start(\\d+)/:amount(\\d+)/:sort/:sortord/:extra/:currline/:filter/:query?",
    component: SiteList,
  },
  {
    name: "PageNotFound",
    path: "*",
    component: PageNotFound,
  },
];

const router = new VueRouter({ mode: "history", routes: routes });

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
