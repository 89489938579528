<template>
  <tr @click.capture="updateCurrLine(pageCp.id)" :class="{ 'has-background-success-light': pageCp.id === currLine }">
    <td class="has-text-centered nowrap">
      <div class="control">
        <input class="mt-2" @change="selectItem(pageCp.id, pageCp.url)" v-model="checked" type="checkbox" />
      </div>
    </td>
    <td>
      <a class="cut-text max-width-600" target="_blank" :href="`http://qsssgl.com/?url=${pageCp.url}`">{{ pageCp.title }}</a>
    </td>
    <td class="has-text-centered nowrap">
      <span v-if="pageCp.inCrawler" v-html="htmlCheck"></span>
      <span v-else v-html="htmlBan"></span>
    </td>
    <td class="nowrap">
      <span class="cut-text max-width-600">{{ pageCp.titlePage }}</span>
    </td>
    <td class="has-text-centered nowrap">
      <span>{{ pageCp.language }}</span>
    </td>
  </tr>
</template>

<script>
export default {
  name: "PageListDetail",
  props: {
    page: Object,
    currLine: Number,
    selectedItemsMap: Map,
  },
  data() {
    return {
      pageCp: {},
      checked: false,
      htmlCheck: '<i class="fa fa-check"></i>',
      htmlBan: '<i class="fa fa-ban"></i>',
    };
  },

  created() {
    this.pageCp = this.page;
  },

  watch: {
    selectedItemsMap: {
      handler: function (newVal) {
        if (newVal.has(this.pageCp.id)) {
          this.checked = true;
        } else {
          this.checked = false;
        }
      },
    },
  },

  methods: {
    selectItem(id, url) {
      if (this.checked) {
        this.$emit("addSelectedItems", [id, url]);
      } else {
        this.$emit("removeSelectedItems", id);
      }
    },

    updateCurrLine(id) {
      this.$emit("update:currLine", id);
    },
  },
};
</script>

<style></style>
