<template>
  <th class="has-text-centered nowrap" :class="{ 'width-4': sortKey !== 'urlUnicodeAccentLess', 'width-30': sortKey === 'urlUnicodeAccentLess', 'width-8': sortKey === 'themeName' }">
    <span
      class="is-size-6 is-vcentered is-clickable"
      @click="
        sortOrdCp = sortCp !== sortKey ? 'ASC' : sortOrdCp === 'ASC' ? 'DESC' : 'ASC';
        sortCp = sortKey;
      "
      :title="l(title, 'menuHover')"
      >{{ l(title, "menu") }}
    </span>
    <span class="is-pulled-right"
      ><i
        class="fa fa-sort-asc is-size-5"
        :class="{ 'has-text-success': sortCp === sortKey && sortOrdCp === 'ASC' }"
        @click="
          sortCp = sortKey;
          sortOrdCp = 'ASC';
        "
      ></i
      ><i
        class="fa fa-sort-desc is-size-5"
        :class="{ 'has-text-success': sortCp === sortKey && sortOrdCp === 'DESC' }"
        @click="
          sortCp = sortKey;
          sortOrdCp = 'DESC';
        "
      ></i
    ></span>
    <div class="control mt-3">
      <input class="input is-small" spellcheck="false" type="text" v-model.lazy.trim="filterCp" />
    </div>
  </th>
</template>

<script>
import { getBooleanFromString } from "../sharedfn.js";

export default {
  name: "TableHead",
  props: {
    title: String,
    sortKey: String,
    sort: String,
    sortOrd: String,
    filter: String,
    currStart: Number,
    type: String,
  },

  computed: {
    sortCp: {
      set(newVal) {
        this.$emit("update:sort", newVal);
        this.$emit("update:currStart", 0);
      },
      get() {
        return this.sort;
      },
    },
    sortOrdCp: {
      set(newVal) {
        this.$emit("update:sortOrd", newVal);
        this.$emit("update:currStart", 0);
      },
      get() {
        return this.sortOrd;
      },
    },
    filterCp: {
      set(newVal) {
        newVal = this.type === "boolean" && ["0", "1"].includes(newVal) ? Boolean(+newVal).toString() : this.type === "number" && newVal ? `$${newVal}` : newVal;
        const filterStrArray = [];
        const filterParam = this.filter !== "-" ? this.filter : "";
        const array0 = filterParam.split(";");

        if (array0?.length) {
          filterStrArray.push(...array0);
        } else if (filterParam) {
          filterStrArray.push(filterParam);
        }

        const filter1 = filterStrArray.filter((flt) => flt.split(":").length === 2 && flt.split(":")[0] !== this.sortKey).join(";");
        const filter = !filter1 && newVal ? `${this.sortKey}:${encodeURIComponent(newVal)}` : filter1 && newVal ? `${filter1};${this.sortKey}:${encodeURIComponent(newVal)}` : filter1 && !newVal ? `${filter1}` : "-";

        this.$emit("update:filter", filter);
        this.$emit("update:currStart", 0);
      },
      get() {
        const filterStrArray = [];
        const filterParam = this.filter !== "-" ? this.filter : "";
        const array0 = filterParam.split(";");

        if (array0?.length) {
          filterStrArray.push(...array0);
        } else if (filterParam) {
          filterStrArray.push(filterParam);
        }

        let val = "";
        for (let i = 0; i < filterStrArray.length; i++) {
          const arr = filterStrArray[i].split(":");
          if (arr?.length === 2 && arr[0] === this.sortKey) {
            val = this.type === "boolean" && ["false", "true"].includes(arr[1]) ? (getBooleanFromString(arr[1]) ? "1" : "0") : this.type === "number" ? arr[1].substring(1) : arr[1];
          }
        }
        return decodeURIComponent(val);
      },
    },
  },
};
</script>

<style></style>
