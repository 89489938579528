<template>
  <tr @click.capture="updateCurrLine(postCp.id)" :class="{ 'has-background-success-light': postCp.id === currLine }">
    <td class="has-text-centered nowrap">
      <div class="control">
        <input class="mt-2" @change="selectItem(postCp.id, postCp.url)" v-model="checked" type="checkbox" />
      </div>
    </td>
    <td>
      <a class="cut-text max-width-600" target="_blank" :href="`http://qsssgl.com/?url=${postCp.url}`">{{ postCp.title }}</a>
    </td>
    <td class="has-text-centered nowrap">
      <span v-if="postCp.inCrawler" v-html="htmlCheck"></span>
      <span v-else v-html="htmlBan"></span>
    </td>
    <td class="nowrap">
      <span class="cut-text max-width-600">{{ postCp.titlePost }}</span>
    </td>
    <td class="has-text-centered nowrap">
      <span :class="{ 'cut-text ': cutCatNames }" class="max-width-200" @click.prevent="cutCatNames = !cutCatNames">{{ postCp.categories?.join(", ") }}</span>
    </td>
    <td class="has-text-centered nowrap">
      <span>{{ postCp.language }}</span>
    </td>
    <td class="has-text-centered nowrap">
      <span v-if="postCp.image" v-html="htmlCheck"></span>
      <span v-else v-html="htmlBan"></span>
    </td>
  </tr>
</template>

<script>
export default {
  name: "PostListDetail",
  props: {
    post: Object,
    currLine: Number,
    selectedItemsMap: Map,
  },
  data() {
    return {
      postCp: {},
      checked: false,
      htmlCheck: '<i class="fa fa-check"></i>',
      htmlBan: '<i class="fa fa-ban"></i>',
      cutCatNames: true,
    };
  },

  created() {
    this.postCp = this.post;
  },

  watch: {
    selectedItemsMap: {
      handler: function (newVal) {
        if (newVal.has(this.postCp.id)) {
          this.checked = true;
        } else {
          this.checked = false;
        }
      },
    },
  },

  methods: {
    selectItem(id, url) {
      if (this.checked) {
        this.$emit("addSelectedItems", [id, url]);
      } else {
        this.$emit("removeSelectedItems", id);
      }
    },

    updateCurrLine(id) {
      this.$emit("update:currLine", id);
    },
  },
};
</script>

<style></style>
