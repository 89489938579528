<template>
  <div class="mb-5">
    <nav v-show="$route.name !== 'Login'" class="navbar top is-dark" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <a class="navbar-item" href="/">
          <img src="@/assets/logo.png" alt="Crawler" width="115" height="36" />
        </a>
        <a role="button" class="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarTop" :class="{ 'is-active': menuMob }" @click.prevent="menuMob = !menuMob">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navbarTop" class="navbar-menu" :class="{ 'is-active': menuMob }">
        <!-- <div class="navbar-start">
          <a class="navbar-item" @click.prevent="$router.push('/')" href="/">{{ l("Home") }}</a>
        </div> -->

        <div class="navbar-end">
          <div class="navbar-item">
            <div class="buttons">
              <a class="button is-danger is-small is-rounded" @click.prevent="logout()">
                <span>{{ l("Logout") }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <div id="app" class="container mt-3 is-fluid">
      <router-view :key="params"></router-view>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "App",
  data() {
    return {
      menuMob: false,
    };
  },

  computed: {
    params() {
      const currStart = +(this.$route.params.start ?? 0);
      const amount = +(this.$route.params.amount ?? 12);
      const sort = this.$route.params.sort ?? "urlUnicodeAccentLess";
      const sortOrd = this.$route.params.sortord ?? "ASC";
      const extraCol = this.$route.params.extra ?? "homePageStat";
      const filter = this.$route.params.filter ?? "-";
      const searchQuery = this.$route.params.query ?? "";
      return currStart + amount + sort + sortOrd + extraCol + filter + searchQuery;
    },
  },

  methods: {
    async logout() {
      try {
        await axios.get(`${this.apiInterface.logout}`);
        this.$router.push("/login");
      } catch (err) {
        console.log(err.message);
      }
    },
  },
};
</script>

<style lang="scss">
/* import bulma */
@import "~bulma/bulma.sass";
/* import fontawesome */
@import "~font-awesome/css/font-awesome.css";

.cut-text {
  max-width: 900px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.navbar.top {
  background-color: #000000;
}

.nowrap {
  white-space: nowrap;
}

.max-width-200 {
  max-width: 200px;
}
.max-width-400 {
  max-width: 400px;
}
.max-width-500 {
  max-width: 500px;
}
.max-width-600 {
  max-width: 600px;
}
.max-width-800 {
  max-width: 800px;
}

.width-2 {
  width: 2%;
}
.width-4 {
  width: 4%;
}
.width-8 {
  width: 8%;
}
.width-12 {
  width: 12%;
}
.width-20 {
  width: 20%;
}
.width-30 {
  width: 30%;
}
.width-40 {
  width: 40%;
}
.width-60 {
  width: 60%;
}
.width-80 {
  width: 80%;
}

th .is-pulled-right {
  position: relative;
}
th .is-pulled-right .fa.fa-sort-asc,
th .is-pulled-right .fa.fa-sort-desc {
  height: 10px;
  display: inline;
  position: absolute;
  top: -3px;
  left: -5px;
}
th .is-pulled-right .fa.fa-sort-desc {
  top: 10px;
}

.datagrid-responsive {
  max-width: 100%;
  margin: 0 0 32px;
  overflow-x: auto;
  overflow-y: hidden;
}

/* 
tr.has-background-success-light td.has-background-warning-light {
  background-color: unset !important;
}
*/
</style>
