<template>
  <div class="modal" :class="{ 'is-active': showModal }">
    <div class="modal-background" @click="showModal = false"></div>
    <div class="modal-content has-background-white py-5 px-5">
      <h3 class="title mb-3 is-5">{{ l("Pages to remove") }} ({{ selectedItemsMap.size }}):</h3>
      <div v-for="[id, url] in selectedItemsMap" :key="id" class="field mb-0 mx-4">
        <span class="cut-text max-width-500 is-size-6">{{ url }}</span>
      </div>
      <div class="field is-grouped is-grouped-centered mt-4">
        <p class="control">
          <a class="button is-danger" @click.prevent="deletePages" :class="{ 'is-loading': isLoading }"
            >{{ l("Remove") }}<span class="ml-1 has-text-white">({{ counter }})</span></a
          >
        </p>
        <p class="control">
          <a class="button is-light" @click.prevent="showModal = false">{{ l("Cancel") }}</a>
        </p>
      </div>
    </div>
    <button class="modal-close is-large" aria-label="close" @click.prevent="showModal = false">
      {{ l("Cancel") }}
    </button>
  </div>
</template>

<script>
import axios from "axios";
import queryString from "query-string";

export default {
  name: "PageListTopDelete",
  props: {
    displayDeleteModal: Boolean,
    selectedItemsMap: Map,
    siteInfo: Object,
  },

  data() {
    return {
      isLoading: false,
      counter: this.selectedItemsMap.size,
    };
  },

  computed: {
    showModal: {
      set(newVal) {
        this.$emit("update:displayDeleteModal", newVal);
      },
      get() {
        return this.displayDeleteModal;
      },
    },
  },

  methods: {
    async deletePages() {
      this.isLoading = true;
      try {
        for (const [id] of this.selectedItemsMap) {
          await axios.post(this.apiInterface.wpdeletepost, queryString.stringify({ url: this.siteInfo?.url, id: id }));
          this.counter--;
        }
      } catch (err) {
        console.log(err.message);
      }
      this.$parent.$emit("update:currLine", 0);
      this.$parent.$emit("clearSelectedItems", "deleteAction");
      this.$emit("crawlWpApi");
      this.isLoading = false;
      this.showModal = false;
    },
  },
};
</script>

<style></style>
