<template>
  <div>
    <table class="table is-striped is-bordered mt-2 is-fullwidth">
      <caption class="subtitle is-size-5 py-1 mb-0 has-background-link-light">
        {{
          caption
        }}
        ({{
          detail.length
        }})
      </caption>
      <thead v-if="detail.length">
        <tr>
          <th class="has-text-centered width-40">{{ l("Link") }}</th>
          <th class="has-text-centered width-8">{{ l("inCategory") }}</th>
          <th class="has-text-centered width-8">{{ l("inMenu") }}</th>
          <th class="has-text-centered width-8">{{ l("Type") }}</th>
          <th class="has-text-centered width-20">{{ l("Anchor / Name") }}</th>
          <th class="has-text-centered width-20">{{ l("N. Posts") }}</th>
          <th class="has-text-centered width-20">{{ l("Language") }}</th>
          <th class="has-text-centered width-8">{{ l("Status") }}</th>
        </tr>
      </thead>
      <tbody v-if="detail.length">
        <tr v-for="(entry, index) in detail" :key="index" :class="{ 'has-background-danger-light': error.find((el) => el.url === entry.url) }">
          <td>
            <a class="cut-text max-width-400" target="_blank" :href="`http://qsssgl.com/?url=${entry.url}`">
              {{ entry.title }}
            </a>
          </td>
          <td class="has-text-centered nowrap">
            <span v-if="entry.inCategory === 1"><i class="fa fa-check"></i></span>
            <span v-else><i class="fa fa-ban"></i></span>
          </td>
          <td class="has-text-centered nowrap">
            <span v-if="entry.inMenu === 1"><i class="fa fa-check"></i></span>
            <span v-else><i class="fa fa-ban"></i></span>
          </td>
          <td>
            <span class="cut-text max-width-200">{{ entry.type }}</span>
          </td>
          <td>
            <span class="cut-text max-width-200">{{ entry.inMenu ? entry?.anchor : entry?.name }}</span>
          </td>
          <td>
            <span class="cut-text max-width-200">{{ entry?.count }}</span>
          </td>
          <td>
            <span class="cut-text max-width-200">{{ entry?.language }}</span>
          </td>
          <td>
            <span class="cut-text max-width-200">{{ entry?.status }}</span>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td class="has-text-centered">{{ l("No Links") }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "HpLinkListLangMenu",
  props: {
    detail: Array,
    error: Array,
    caption: String,
  },
};
</script>

<style></style>
