<template>
  <div>
    <SiteListTop
      @refreshList="onRefreshList"
      @clearSelectedItems="onClearSelectedItems"
      :showInputUrl.sync="showInputUrl"
      :amount.sync="amount"
      :searchQuery.sync="searchQuery"
      :queueNb.sync="queueNb"
      :extraCol.sync="extraCol"
      :selectedItemsMap="selectedItemsMap"
      :currLine.sync="currLine"
    />
    <div>
      <div class="datagrid-responsive mb-4">
        <table class="table is-striped is-bordered mb-2 is-fullwidth">
          <thead>
            <tr v-switch="extraCol">
              <th class="has-text-centered width-2">
                <div class="control">
                  <input class="mt-6" v-model="checkAll" type="checkbox" />
                </div>
              </th>
              <TableHead :title="'urlUnicodeAccentLess'" :sortKey="'urlUnicodeAccentLess'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="string" />
              <TableHead :title="'verifySSL'" :sortKey="'verifySSL'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="boolean" />
              <TableHead :title="'language'" :sortKey="'language'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="string" />
              <TableHead :title="'siteType'" :sortKey="'siteType'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="string" />
              <TableHead :title="'siteEtat'" :sortKey="'siteEtat'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="string" />
              <TableHead v-case="'homePageStat'" :title="'hpTotalLink'" :sortKey="'hpTotalLink'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <TableHead v-case="'homePageStat'" :title="'hpInterLink'" :sortKey="'hpInterLink'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="string" />
              <TableHead v-case="'homePageStat'" :title="'hpExterLink'" :sortKey="'hpExterLink'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="string" />
              <TableHead v-case="'homePageStat'" :title="'hpMenuLinksCombo'" :sortKey="'hpMenuLinksCombo'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <TableHead v-case="'homePageStat'" :title="'hpMenuLinksError'" :sortKey="'hpMenuLinksError'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <TableHead v-case="'homePageStat'" :title="'hpTotalError'" :sortKey="'hpTotalError'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <TableHead v-case="'homePageStat'" :title="'hpLoadingTime'" :sortKey="'hpLoadingTime'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <TableHead v-case="'homePageStat'" :title="'hpStatus'" :sortKey="'hpStatus'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="string" />
              <TableHead :title="'themeName'" :sortKey="'themeName'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="string" />
              <TableHead v-case="'internalLink'" :title="'categoriesNb'" :sortKey="'categoriesNb'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <TableHead v-case="'internalLink'" :title="'categoriesNames'" :sortKey="'categoriesNames'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="string" />
              <TableHead v-case="'internalLink'" :title="'postRatio'" :sortKey="'postRatio'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="string" />
              <TableHead v-case="'internalLink'" :title="'postNoCrawl'" :sortKey="'postNoCrawl'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <TableHead v-case="'internalLink'" :title="'postLangOth'" :sortKey="'postLangOth'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <TableHead v-case="'internalLink'" :title="'postNoCategory'" :sortKey="'postNoCategory'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <TableHead v-case="'internalLink'" :title="'postInUncategorized'" :sortKey="'postInUncategorized'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <TableHead v-case="'internalLink'" :title="'pageRatio'" :sortKey="'pageRatio'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="string" />
              <TableHead v-case="'internalLink'" :title="'pageNoCrawl'" :sortKey="'pageNoCrawl'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <TableHead v-case="'internalLink'" :title="'pageLangOth'" :sortKey="'pageLangOth'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <TableHead v-case="'internalLink'" :title="'subLinkCount'" :sortKey="'subLinkCount'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <TableHead v-case="'internalLink'" :title="'wpApiSubLinkCount'" :sortKey="'wpApiSubLinkCount'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <TableHead v-case="'errorLink'" :title="'errorSum'" :sortKey="'errorSum'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <TableHead v-case="'errorLink'" :title="'doubleInternLink'" :sortKey="'doubleInternLink'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <TableHead v-case="'errorLink'" :title="'doubleExternLink'" :sortKey="'doubleExternLink'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <TableHead v-case="'errorLink'" :title="'intranetDomainError'" :sortKey="'intranetDomainError'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <TableHead v-case="'errorLink'" :title="'status400'" :sortKey="'status400'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <TableHead v-case="'errorLink'" :title="'status401'" :sortKey="'status401'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <TableHead v-case="'errorLink'" :title="'status403'" :sortKey="'status403'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <TableHead v-case="'errorLink'" :title="'status404'" :sortKey="'status404'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <TableHead v-case="'errorLink'" :title="'status410'" :sortKey="'status410'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <TableHead v-case="'errorLink'" :title="'status499'" :sortKey="'status499'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <TableHead v-case="'errorLink'" :title="'status500'" :sortKey="'status500'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <TableHead v-case="'errorLink'" :title="'status503'" :sortKey="'status503'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <TableHead v-case="'errorLink'" :title="'status520'" :sortKey="'status520'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <TableHead v-case="'errorLink'" :title="'statusIn500To599'" :sortKey="'statusIn500To599'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <TableHead v-case="'errorLink'" :title="'statusIn400To499'" :sortKey="'statusIn400To499'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <TableHead v-case="'errorLink'" :title="'urlWithHashMark'" :sortKey="'urlWithHashMark'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <TableHead v-case="'errorLink'" :title="'aTagWithoutAnchor'" :sortKey="'aTagWithoutAnchor'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <TableHead v-case="'errorLink'" :title="'urlWithErrorChar'" :sortKey="'urlWithErrorChar'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <TableHead v-case="'errorLink'" :title="'webArchiveLink'" :sortKey="'webArchiveLink'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <TableHead v-case="'errorLink'" :title="'netlinkingLink'" :sortKey="'netlinkingLink'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <TableHead v-case="'errorLink'" :title="'trgoogLink'" :sortKey="'trgoogLink'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <TableHead v-case="'errorLink'" :title="'domainWithPort'" :sortKey="'domainWithPort'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <TableHead v-case="'errorLink'" :title="'domainError'" :sortKey="'domainError'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <TableHead v-case="'errorLink'" :title="'timeoutError'" :sortKey="'timeoutError'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <TableHead v-case="'errorLink'" :title="'unknownFileError'" :sortKey="'unknownFileError'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <TableHead v-case="'errorLink'" :title="'unknownError'" :sortKey="'unknownError'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <TableHead v-case="'errorLink'" :title="'requestNullError'" :sortKey="'requestNullError'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <TableHead v-case="'errorLink'" :title="'otherCases'" :sortKey="'otherCases'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <TableHead v-case="'postKeywords'" :title="'postLgSvKw'" :sortKey="'postLgSvKw'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <TableHead v-case="'postKeywords'" :title="'postLgKw'" :sortKey="'postLgKw'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <TableHead v-case="'postKeywords'" :title="'postCrawl'" :sortKey="'postCrawl'" :sort.sync="sort" :sortOrd.sync="sortOrd" :filter.sync="filter" :currStart.sync="currStart" type="number" />
              <th v-case="'homePageStat'" class="has-text-centered width-4">{{ l("Actions", "menu") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-show="loaderActive">
              <td colspan="100%">
                <LoadingSpinner :active="loaderActive" :message="l('Loading data...')" />
              </td>
            </tr>
            <SiteListDetail
              v-show="sites.length"
              v-for="site in sites"
              :key="site.fileName"
              @refreshList="onRefreshList"
              @addSelectedItems="onAddSelectedItems"
              @removeSelectedItems="onRemoveSelectedItems"
              :queueNb.sync="queueNb"
              :currLine.sync="currLine"
              :showInputUrl="showInputUrl"
              :extraCol="extraCol"
              :site="site"
              :selectedItemsMap="selectedItemsMap"
              :sort="sort"
            />
            <tr v-show="!sites.length && !loaderActive">
              <td colspan="100%">
                <div><NoResult :message="l('No results')" /></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <PaginationBar :amount="amount" :currStart.sync="currStart" :totalItemCount="totalItemCount" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { getFormatedDate } from "../sharedfn.js";
import loaderMixin from "../mixins/loader.js";
import LoadingSpinner from "./LoadingSpinner.vue";
import NoResult from "./NoResult.vue";
import SiteListTop from "./SiteList_Top.vue";
import TableHead from "./TableHead.vue";
import SiteListDetail from "./SiteList_Detail.vue";
import PaginationBar from "./PaginationBar.vue";

export default {
  name: "SiteList",
  mixins: [loaderMixin],
  components: {
    LoadingSpinner,
    NoResult,
    SiteListTop,
    TableHead,
    SiteListDetail,
    PaginationBar,
  },
  data() {
    return {
      totalItemCount: 0,
      amount: 12,
      currStart: 0,
      searchQuery: "",
      sort: "urlUnicodeAccentLess",
      sortOrd: "ASC",
      sites: [],
      showInputUrl: false,
      currLine: "-",
      queueNb: 0,
      extraCol: "homePageStat",
      filter: "-",
      selectedItemsMap: new Map(),
      checkAll: false,
    };
  },

  created() {
    this.currStart = +(this.$route.params.start ?? 0);
    this.amount = +(this.$route.params.amount ?? 12);
    this.sort = this.$route.params.sort ?? "urlUnicodeAccentLess";
    this.sortOrd = this.$route.params.sortord ?? "ASC";
    this.extraCol = this.$route.params.extra ?? "homePageStat";
    this.currLine = this.$route.params.currline ?? "-";
    this.filter = this.$route.params.filter ?? "-";
    this.searchQuery = this.$route.params.query ?? "";
  },

  mounted() {
    this.getSiteList();
  },

  watch: {
    params: {
      handler: function () {
        const newUrl = `/${this.currStart}/${this.amount}/${this.sort}/${this.sortOrd}/${this.extraCol}/${this.currLine}/${this.filter}/${encodeURIComponent(this.searchQuery)}`;
        const currentPath = this.$router.history.current.path;
        // Fix: suffixed "/" added by nginx
        if (currentPath !== newUrl && currentPath !== `${newUrl}/`) {
          this.$router.push(newUrl);
        }
      },
    },
    checkAll: {
      handler: function (newVal) {
        if (newVal) {
          this.selectedItemsMap = new Map();
          this.sites.forEach((obj) => {
            this.selectedItemsMap.set(obj.fileName, obj.firstUrl);
          });
        } else {
          this.selectedItemsMap = new Map();
        }
      },
    },
  },

  computed: {
    params() {
      return this.currStart + this.amount + this.sort + this.sortOrd + this.extraCol + this.currLine + this.filter + this.searchQuery;
    },
  },

  methods: {
    async getSiteList() {
      this.sites = [];
      this.showLoader();
      try {
        const response = await axios.get(
          `${this.apiInterface.sitelist}?start=${this.currStart}&amount=${this.amount}&sort=${this.sort}&sortord=${this.sortOrd}&extra=${this.extraCol}&filter=${this.filter}&query=${this.searchQuery}`
        );
        this.sites = response.data.dataList ?? [];
        this.totalItemCount = response.data.countTotal ?? 0;
      } catch (err) {
        console.log(err.message);
      }
      this.hideLoader();
    },

    onRefreshList() {
      this.getSiteList();
    },

    onAddSelectedItems([fileName, url]) {
      this.selectedItemsMap.set(fileName, url);
    },

    onRemoveSelectedItems(fileName) {
      this.selectedItemsMap.delete(fileName);
    },

    onClearSelectedItems() {
      this.selectedItemsMap = new Map();
      this.checkAll = false;
    },

    getDate(dateStr) {
      return getFormatedDate(dateStr);
    },
  },
};
</script>

<style></style>
