<template>
  <div class="container">
    <div>
      <LoadingSpinner :active="loaderActive" :message="l('Loading data...')" />
      <HpLinkListLang v-for="(hpDetails, index) in hpLinks" :key="index" :hpLinks="hpDetails" @refreshHpLinkList="getHpLinkList" :firstUrl="firstUrl" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import loaderMixin from "../mixins/loader.js";
import LoadingSpinner from "./LoadingSpinner.vue";
import HpLinkListLang from "./HpLinkList_Lang.vue";

export default {
  name: "HpLinkList",
  mixins: [loaderMixin],
  components: {
    LoadingSpinner,
    HpLinkListLang,
  },
  data() {
    return {
      firstUrl: "",
      hpLinks: [],
    };
  },

  mounted() {
    this.getHpLinkList();
  },

  methods: {
    async getHpLinkList() {
      this.showLoader();
      try {
        this.hpLinks = [];
        const apiUrl = `${this.apiInterface.hplink}?file=${this.$route.params.file}`;
        const response = await axios.get(apiUrl);
        const data = response.data ?? {};
        this.hpLinks.push(data);
        this.firstUrl = data?.siteInfo?.url;
        const languageOther = data?.siteInfo?.languageOther;
        if (languageOther?.length) {
          for (let i = 0; i < languageOther?.length; i++) {
            this.hpLinks.push(data?.[`language-${languageOther[i]}`]);
          }
        }
      } catch (err) {
        console.log(err.message);
      }
      this.hideLoader();
    },
  },
};
</script>

<style></style>
