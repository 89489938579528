<template>
  <tr>
    <td>
      <a class="cut-text max-width-400" target="_blank" :href="`http://qsssgl.com/?url=${detail.url}`">
        {{ detail.title }}
      </a>
    </td>
    <td>
      <span class="max-width-800" v-html="getKeywordsHtml()"></span>
    </td>
  </tr>
</template>

<script>
export default {
  name: "PostKeywordsListDetail",
  props: {
    detail: Object,
  },
  methods: {
    getKeywordsHtml() {
      let html = "";
      for (const keyword of this.detail.keywords) {
        html += `${keyword[0]} (${keyword[1]}) <br>`;
      }
      return html;
    },
  },
};
</script>

<style></style>
