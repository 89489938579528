<template>
  <div class="modal" :class="{ 'is-active': showModal }">
    <div class="modal-background" @click="showModal = false"></div>
    <div class="modal-content has-background-white py-5 px-5 width-60">
      <div class="columns mb-0">
        <div class="column is-6 py-0">
          <h3 class="title is-5 py-2">{{ l("Manage queue (in execution)") }}:</h3>
        </div>
        <div class="column is-6 has-text-right py-0">
          <div class="field mb-0 is-grouped is-grouped-right">
            <div class="control">
              <a class="button is-danger" :class="{ 'is-loading': isLoadingCl }" @click.prevent="deleteAll">{{ l("Clear") }}</a>
            </div>
            <div class="control">
              <a class="button is-success" :class="{ 'is-loading': isLoadingCl }" @click.prevent="getQueueList">{{ l("Refresh") }}</a>
            </div>
            <p class="control">
              <a class="button is-info" @click.prevent="showModal = false">{{ l("Close") }}</a>
            </p>
          </div>
        </div>
      </div>
      <LoadingSpinner :active="loaderActive" :message="l('Loading data...')" />
      <div v-show="queueEntries.length">
        <table class="table is-striped is-bordered mt-2 is-fullwidth">
          <thead>
            <tr>
              <th>{{ l("URL") }}</th>
              <th class="has-text-centered width-8">{{ l("Type") }}</th>
              <th class="has-text-centered width-8">{{ l("State") }}</th>
              <th class="has-text-centered width-12">{{ l("Added") }}</th>
              <th class="has-text-centered width-12">{{ l("Start") }}</th>
              <th class="has-text-centered width-12">{{ l("Actions") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="entry in queueEntries" :key="entry.url">
              <td>{{ entry.url }}</td>
              <td class="has-text-centered">{{ entry.type }}</td>
              <td class="has-text-centered">{{ entry.state }}</td>
              <td class="has-text-centered is-size-7 is-vcentered">{{ getDate(entry.timestamp) }}</td>
              <td class="has-text-centered is-size-7 is-vcentered">{{ getDate(entry.starttime) }}</td>
              <td class="has-text-centered">
                <div class="field mb-0 is-grouped is-grouped-centered">
                  <p class="control">
                    <a class="button is-link is-small" @click.prevent="rescanEntry(entry.url, entry.type)"
                      ><span>{{ l("Resc.") }}</span></a
                    >
                  </p>
                  <p class="control">
                    <a class="button is-danger is-small" @click.prevent="deleteEntry(entry.url, entry.type)"
                      ><span>{{ l("Dele.") }}</span></a
                    >
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-show="!queueEntries.length && !loaderActive"><NoResult :message="l('Empty Queue.')" /></div>
    </div>
    <button class="modal-close is-large" aria-label="close" @click.prevent="showModal = false">
      {{ l("Cancel") }}
    </button>
  </div>
</template>

<script>
import axios from "axios";
import { getFormatedDate } from "../sharedfn.js";
import loaderMixin from "../mixins/loader.js";
import LoadingSpinner from "./LoadingSpinner.vue";
import NoResult from "./NoResult.vue";

export default {
  name: "SiteListTopQueue",
  mixins: [loaderMixin],
  props: {
    displayQueueModal: Boolean,
    queue: Number,
  },
  components: {
    LoadingSpinner,
    NoResult,
  },

  data() {
    return {
      queueEntries: [],
      isLoadingCl: false,
    };
  },

  mounted() {
    this.getQueueList();
  },

  computed: {
    showModal: {
      set(newVal) {
        this.$emit("update:displayQueueModal", newVal);
      },
      get() {
        return this.displayQueueModal;
      },
    },
  },

  methods: {
    async getQueueList() {
      this.queueEntries = [];
      this.showLoader();
      try {
        const response = await axios.get(`${this.apiInterface.qall}`);
        this.queueEntries = response.data.results ?? [];
        this.$emit("update:queue", this.queueEntries.length);
      } catch (err) {
        console.log(err.message);
      }
      this.hideLoader();
    },

    async deleteAll() {
      this.isLoadingCl = true;
      try {
        await axios.get(`${this.apiInterface.qclear}`);
        this.queueEntries = [];
        this.$emit("update:queue", 0);
        this.showModal = false;
      } catch (err) {
        console.log(err.message);
      }
      this.isLoadingCl = false;
    },

    async rescanEntry(url, type) {
      try {
        await axios.get(`${this.apiInterface.qdelete}?url=${encodeURIComponent(url)}&type=${type}`);
        await axios.get(`${this.apiInterface.qcrawl}?url=${encodeURIComponent(url)}&type=${type}`);
        await this.getQueueList();
      } catch (err) {
        console.log(err.message);
      }
    },

    async deleteEntry(url, type) {
      try {
        await axios.get(`${this.apiInterface.qdelete}?url=${encodeURIComponent(url)}&type=${type}`);
        await this.getQueueList();
      } catch (err) {
        console.log(err.message);
      }
    },

    getDate(dateStr) {
      return getFormatedDate(dateStr);
    },
  },
};
</script>

<style scoped>
.noresult.section,
.loading.section {
  padding: 0;
}
</style>
