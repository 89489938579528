const getFormatedDate = (dateStr) => {
  if (dateStr) {
    const dateObj = new Date(dateStr);
    const date = dateObj.getDate();
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();
    const hours = (dateObj.getHours() < 10 ? "0" : "") + dateObj.getHours();
    const minutes = (dateObj.getMinutes() < 10 ? "0" : "") + dateObj.getMinutes();
    const seconds = (dateObj.getSeconds() < 10 ? "0" : "") + dateObj.getSeconds();
    return date + "-" + month + "-" + year + " " + hours + ":" + minutes + ":" + seconds;
  }
  return "-";
};

const waitFor = (ms) => new Promise((r) => setTimeout(r, ms));

const removeTrailingSlash = (str) => str?.replace(/\/+$/, "");

const getBooleanFromString = (val) => {
  const num = +val;
  return !isNaN(num) ? !!num : !!String(val).toLowerCase().replace(!!0, "");
};

module.exports = {
  getBooleanFromString,
  removeTrailingSlash,
  getFormatedDate,
  waitFor,
};
