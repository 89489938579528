<template>
  <div class="columns">
    <div class="column column is-8-tablet is-6-desktop is-4-fullhd is-offset-0-tablet is-offset-2-desktop is-offset-4-fullhd">
      <nav v-show="stVals.length > 1" class="pagination is-centered" role="navigation">
        <a class="pagination-previous" :disabled="currSt === stVals[0]" @click.prevent="currSt -= amount">{{ l("Previous") }}</a>
        <a class="pagination-next" :disabled="currSt === stVals[stVals.length - 1]" @click.prevent="currSt += amount">{{ l("Next") }}</a>
        <ul class="pagination-list">
          <li>
            <a :class="{ 'is-current': 0 === currSt }" class="pagination-link" @click.prevent="currSt = 0">1</a>
          </li>
          <li v-show="showDotsL"><span class="pagination-ellipsis">&hellip;</span></li>
          <li v-for="(val, index) in stVals" :key="val">
            <template v-if="(showDotsL = currSt > stVals[2] ? true : false)"></template>
            <template v-if="(showDotsR = currSt < stVals[stVals.length - 3] ? true : false)"></template>
            <a
              v-if="
                index !== 0 &&
                index !== stVals.length - 1 &&
                ([currSt - amount, currSt, currSt + +amount].includes(val) ||
                  (currSt === 0 && [currSt + +amount, currSt + 2 * amount, currSt + 3 * amount].includes(val)) ||
                  (currSt === 0 + +amount && [currSt + 2 * amount].includes(val)) ||
                  (currSt === stVals[stVals.length - 1] - amount && [currSt - 2 * amount].includes(val)) ||
                  (currSt === stVals[stVals.length - 1] && [currSt - amount, currSt - 2 * amount, currSt - 3 * amount].includes(val)))
              "
              :class="{ 'is-current': val === currSt }"
              class="pagination-link"
              @click.prevent="currSt = val"
              >{{ index + 1 }}</a
            >
          </li>
          <li v-show="showDotsR"><span class="pagination-ellipsis">&hellip;</span></li>
          <li>
            <a :class="{ 'is-current': stVals[stVals.length - 1] === currSt }" class="pagination-link" @click.prevent="currSt = stVals[stVals.length - 1]">{{ stVals.length }}</a>
          </li>
        </ul>
      </nav>
    </div>
    <div
      class="column is-flex is-align-items-center is-justify-content-right column is-flex is-align-items-center is-justify-content-right is-4-tablet is-2-desktop is-offset-0-tablet is-offset-2-desktop"
    >
      <span class="title is-size-5">{{ l("Total") }}: {{ totalItemCount }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaginationBar",
  props: {
    totalItemCount: Number,
    amount: Number,
    currStart: Number,
  },
  data() {
    return {
      showDotsL: false,
      showDotsR: false,
    };
  },

  updated() {
    if (!this.stVals.includes(this.currStart)) {
      this.currSt = [...this.stVals].reverse().find((el) => el < this.currStart) ?? 0;
    }
  },

  computed: {
    stVals() {
      const newStVals = [];
      const pagesCount = Math.ceil(this.totalItemCount / this.amount);
      let j = 0;
      for (let i = 0; i < pagesCount; i++) {
        newStVals.push(j);
        j += this.amount;
      }
      return newStVals;
    },

    currSt: {
      set(newVal) {
        this.$emit("update:currStart", newVal);
      },
      get() {
        return this.currStart;
      },
    },
  },
};
</script>

<style></style>
