<template>
  <div class="hero is-fullheight">
    <div class="hero-body is-justify-content-center is-align-items-center">
      <form
        @submit.prevent="
          verifyKey();
          message = '';
        "
      >
        <div class="columns is-flex is-flex-direction-column box">
          <div class="column">
            <h3 class="title is-5">{{ l("Enter your password") }}</h3>
          </div>
          <div class="column">
            <input class="input is-primary" type="text" size="35" v-model="user" :placeholder="l('Login')" required />
          </div>
          <div class="column">
            <input class="input is-primary" type="password" size="35" v-model="password" :placeholder="l('Password')" required />
            <div v-show="message" class="is-size-7 has-text-danger">{{ l(message) }}</div>
          </div>
          <div class="column">
            <button class="button is-primary is-fullwidth" ref="connexion" type="submit">{{ l("Connexion") }}</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import queryString from "query-string";

export default {
  name: "HpLinkList",
  data() {
    return {
      isLoadingLg: false,
      user: "",
      password: "",
      message: "",
    };
  },

  mounted() {
    this.$refs.connexion.focus();
  },

  methods: {
    async verifyKey() {
      this.isLoadingLg = true;
      try {
        const response = await axios.post(this.apiInterface.login, queryString.stringify({ user: this.user, password: this.password }));
        if (response.data?.errorMessage) {
          this.message = response.data?.errorMessage;
        } else {
          //localStorage.isAuth = true;
          this.$router.push("/");
        }
      } catch (err) {
        this.message = "Server error";
        console.log(err.message);
      }
      this.isLoadingLg = false;
    },
  },
};
</script>

<style></style>
